import { ButtonColor } from '@accredible-frontend-v2/new-components/button';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'accredible-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // we are using ViewEncapsulation.None in order to be able to style the dialog
  encapsulation: ViewEncapsulation.None,
})
export class AccredibleDialogTemplateComponent {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  centered = false;
  @Input()
  actionBtnText = this._language.translate('new-confirm-dialog.ok');
  @Input()
  actionBtnColor: ButtonColor = 'primary';
  @Input()
  cancelBtnText = this._language.translate('new-confirm-dialog.cancel');
  @Input()
  actionBtnDisabled = false;
  @Input()
  isLoading = false;
  @Input()
  closeBtn = true;

  @Output()
  actionBtnClicked = new EventEmitter<boolean>();

  constructor(
    private readonly _dialogRef: DialogRef,
    private readonly _language: AccredibleLanguageService,
  ) {}

  /**
   * @param cancelBtnBoolean :
   *  false - clicking cancel button
   *  undefined - clicking the backdrop or the dialog close button
   */
  close(cancelBtnBoolean?: boolean): void {
    this._dialogRef.close(cancelBtnBoolean);
  }

  submit(): void {
    if (!this.isLoading) {
      this.actionBtnClicked.emit();
    }
  }
}
