import { AccredibleLinkComponentModule } from '@accredible-frontend-v2/new-components/link';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { FeaturedReadComponent } from './featured-read.component';

@NgModule({
  imports: [AccredibleLinkComponentModule, TranslocoModule],
  exports: [FeaturedReadComponent],
  declarations: [FeaturedReadComponent],
  providers: [],
})
export class FeaturedReadComponentModule {}
