import { AccredibleCookiesBannerComponentModule } from '@accredible-frontend-v2/components/cookies-banner';
import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleDialogModule } from '@accredible-frontend-v2/new-components/dialog';
import { AccredibleToastModule } from '@accredible-frontend-v2/new-components/toast';
import { AccredibleReadMoreComponent } from '@accredible-frontend-v2/new-components/ui-common';
import { AccredibleAuthenticationServiceModule } from '@accredible-frontend-v2/services/authentication';
import { AccredibleBrowserStorageServiceModule } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesServiceModule } from '@accredible-frontend-v2/services/cookies';
import { AccredibleLanguageServiceModule } from '@accredible-frontend-v2/services/language';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BlogArticleComponentModule } from './components/blog-article/blog-article.component.module';
import { FeaturedVideoComponentModule } from './components/featured-video/featured-video.component.module';
import { FooterComponentModule } from './components/footer/footer.component.module';
import { HeaderComponentModule } from './components/header/header.component.module';
import { TrendingCoursesModule } from './components/trending-courses/trending-courses.module';
import { CourseFinderLoadModule } from './course-finder-load.module';
import { CourseFinderRoutingModule } from './course-finder-routing.module';
import { CourseFinderContainer } from './course-finder.container';
import { CourseFinderApiService } from './services/cf-api/cf-api.service';
import { CoursesEffects } from './stores/courses/courses.effects';
import { CoursesService } from './stores/courses/courses.service';
import { metaReducers, reducers } from './stores/reducer';

const CORE_MODULES = [
  CourseFinderLoadModule,
  CourseFinderRoutingModule,
  HeaderComponentModule,
  FooterComponentModule,
  TrendingCoursesModule,
  AccredibleReadMoreComponent,
  FeaturedVideoComponentModule,
  BlogArticleComponentModule,
  AccredibleCookiesBannerComponentModule,
  AccredibleToastModule,
  AccredibleDialogModule,
  TranslocoModule,
];

const CORE_ACCREDIBLE_LIBS_MODULES = [
  AccredibleBrowserStorageServiceModule,
  AccredibleCookiesServiceModule,
  AccredibleAuthenticationServiceModule.forRoot({ app: 'cf' }),
  AccredibleLanguageServiceModule.forRoot(),
];

@NgModule({
  declarations: [CourseFinderContainer],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    ...CORE_MODULES,
    ...CORE_ACCREDIBLE_LIBS_MODULES,

    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.type !== 'production'
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    EffectsModule.forRoot([CoursesEffects]),
  ],
  providers: [CourseFinderApiService, CoursesService],
  bootstrap: [CourseFinderContainer],
})
export class CourseFinderModule {}
