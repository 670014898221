import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleDialogTemplateComponentModule } from '@accredible-frontend-v2/new-components/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { SignInDialogComponent } from './sign-in-dialog.component';

@NgModule({
  declarations: [SignInDialogComponent],
  imports: [
    CommonModule,
    AccredibleDialogTemplateComponentModule,
    AccredibleButtonComponentModule,
    TranslocoModule,
  ],
})
export class SignInDialogComponentModule {}
