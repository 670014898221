import { AccredibleKey } from '@accredible-frontend-v2/utils/key-enum';
import { FocusableOption } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'accredible-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccredibleListItemComponent implements FocusableOption {
  @HostBinding('tabindex')
  tabindex = '-1';
  @HostBinding('role')
  role = 'list-item';

  @Output()
  selected = new EventEmitter<boolean>();

  constructor(private readonly _element: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (event.key === AccredibleKey.ENTER) {
      this.selected.emit(true);
    }
  }

  focus(): void {
    this._element.nativeElement.focus();
  }
}
