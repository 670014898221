import { Component, ViewChild } from '@angular/core';
import { SwiperSlideDirective } from 'swiper/angular';

@Component({
  selector: 'accredible-slide-item',
  templateUrl: './slide-item.component.html',
  // TODO(Fred): ChangeDetectionStrategy.OnPush can't be used in order for the certificates to be correctly drawn on any slider using the certificate/badge components.
  //  But, this logic will not be needed when we start using thumbnail images instead of the certificate/badge components.
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleSlideItemComponent {
  @ViewChild(SwiperSlideDirective)
  data: SwiperSlideDirective;
}
