import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cf-blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogArticleComponent {
  @Input()
  isLoading = true;
}
