<ng-template>
  <div class="accredible-menu-content"
    role="menu"
    [ngClass]="customClass"
    [cdkTrapFocus]="trapFocus"
    [cdkTrapFocusAutoCapture]="trapFocus"
    (click)="closeMenu()"
    (keydown)="keyHandler($event)">
    <ng-content></ng-content>
  </div>
</ng-template>
