import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { AccredibleSlideItemComponent } from './slide-item.component';

@NgModule({
  imports: [SwiperModule],
  declarations: [AccredibleSlideItemComponent],
  exports: [AccredibleSlideItemComponent],
})
export class AccredibleSlideItemComponentModule {}
