import { FocusKeyManager } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  HostListener,
  QueryList,
} from '@angular/core';
import { AccredibleListItemComponent } from './components/list-item.component';

@Component({
  selector: 'accredible-list',
  templateUrl: './list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleListComponent implements AfterContentInit {
  @HostBinding('role')
  role = 'list';
  @HostBinding('tabindex')
  tabIndex = '0';

  @ContentChildren(AccredibleListItemComponent)
  items: QueryList<AccredibleListItemComponent>;

  private _keyManager: FocusKeyManager<AccredibleListItemComponent>;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    this._keyManager.onKeydown(event);
  }

  ngAfterContentInit(): void {
    this._keyManager = new FocusKeyManager(this.items).withWrap();
  }
}
