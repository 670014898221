import { AccredibleAccountsRedirectionService } from '@accredible-frontend-v2/services/accounts-redirection';
import { FocusMonitor } from '@angular/cdk/a11y';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject } from '@angular/core';

@Component({
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
})
export class SignInDialogComponent {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(DIALOG_DATA) public readonly data: { trigger: ElementRef },
    private readonly _dialogRef: DialogRef,
    private readonly _accountRedirectionService: AccredibleAccountsRedirectionService,
    protected readonly _focusMonitor: FocusMonitor,
  ) {}

  close(): void {
    this._dialogRef.close();
  }

  signIn(): void {
    this._document.location.href = this._accountRedirectionService.getLoginUrlOrigin();
  }
}
