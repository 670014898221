import { AccredibleAvatarComponentModule } from '@accredible-frontend-v2/new-components/avatar';
import { AccredibleMenuComponentModule } from '@accredible-frontend-v2/new-components/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AccredibleAvatarComponentModule,
    AccredibleMenuComponentModule,
    TranslocoModule,
  ],
  exports: [HeaderComponent],
  declarations: [HeaderComponent],
  providers: [],
})
export class HeaderComponentModule {}
