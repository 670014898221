import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleBaseSliderComponentModule } from '@accredible-frontend-v2/new-components/sliders/base-slider';
import { AccredibleSlideItemComponentModule } from '@accredible-frontend-v2/new-components/sliders/slide-item';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { AccredibleSliderMultiComponent } from './slider-multi.component';

@NgModule({
  imports: [
    CommonModule,
    AccredibleBaseSliderComponentModule,
    AccredibleSlideItemComponentModule,
    AccredibleButtonComponentModule,
    TranslocoModule,
  ],
  declarations: [AccredibleSliderMultiComponent],
  exports: [AccredibleSliderMultiComponent],
})
export class AccredibleSliderMultiComponentModule {}
