import { environment } from '@accredible-frontend-v2/envs';

export const addPostHog = (): void => {
  if (environment.type === 'production' && location.hostname !== 'localhost') {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;

    const id: string = 'phc_jq9TvFVhI9val4SDiFcPtmRLjLWWprSaJydmx85FoVX';

    scriptTag.innerHTML = `!function(t, e) {
        var o, n, p, r;
        e.__SV || (window.posthog = e, e._i = [], e.init = function(i, s, a) {
            function g(t, e) {
                var o = e.split(".");
                2 == o.length && (t = t[o[0]], e = o[1]), t[e] = function() {
                    t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                }
            }(p = t.createElement("script")).type = "text/javascript", p.crossOrigin = "anonymous", p.async = !0, p.src = s.api_host.replace(".i.posthog.com", "-assets.i.posthog.com") + "/static/array.js", (r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(p, r);
            var u = e;
            for (void 0 !== a ? u = e[a] = [] : a = "posthog", u.people = u.people || [], u.toString = function(t) {
                    var e = "posthog";
                    return "posthog" !== a && (e += "." + a), t || (e += " (stub)"), e
                }, u.people.toString = function() {
                    return u.toString(1) + ".people (stub)"
                }, o = "init capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted captureException loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug getPageViewId captureTraceFeedback captureTraceMetric".split(" "), n = 0; n < o.length; n++) g(u, o[n]);
            e._i.push([i, s, a])
        }, e.__SV = 1)
    }(document, window.posthog || []);
    posthog.init('${id}', {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'always',
    })`;
    // Note: 'always' above creates profiles for anonymous as well as identified users. Use 'identified_only' instead to only create profiles for identified users.

    document.head.insertBefore(scriptTag, document.head.firstChild);
  }
};

export const sendPostHogEvent = (eventName: string, value: any): void => {
  const postHog = (<any>window)['posthog'];
  if (postHog) {
    postHog.capture(eventName, { property: value });
  }
};

export const getPostHogSessionId = (): string => {
  const postHog = (<any>window)['posthog'];
  return postHog ? postHog.get_session_id() : null;
};
