import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'a[accredible-link], button[accredible-link]',
  templateUrl: './link.component.html',
  styleUrls: ['link.component.scss'],
  // ViewEncapsulation.None in order to be able to style accredible-icon without using ::ng-deep
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleLinkComponent {
  @Input()
  size: 'small' | 'normal' = 'normal';
  @Input()
  color: 'light' | 'dark' = 'dark';
  @Input()
  icon = 'external';
  @Input()
  iconPosition: 'right' | 'left' = 'right';
  @Input()
  invertIconRtl = false;
  @Input()
  disabled = false;

  @HostBinding('class')
  get classes(): string {
    return `${this.size} ${this.color}`;
  }

  @HostBinding('attr.tabindex')
  get tabIndex(): number {
    return this.disabled ? -1 : 0;
  }

  @HostBinding('attr.aria-disabled')
  get ariaDisabled(): string | null {
    return this.disabled ? 'true' : null;
  }
}
