import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'accredible-email-share-button',
  templateUrl: './email-share-button.component.html',
  styleUrls: ['../social-share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailShareButtonComponent {
  @Input()
  url = this._document.location.href;
  @Input()
  text = '';

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}
}
