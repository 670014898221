<footer *transloco="let t; read: 'cf-footer'"
  class="cf-content-horizontal">
  <div class="top">
    <div class="description">
      <img src="/assets/images/accredible_logo_text.png"
        [alt]="t('accredible_logo')">

      <div class="font-multiline-normal-regular">
        {{ t('accredible_description') }}
      </div>
    </div>

    <div class="accredible-links">
      <div>
        <div>
          <div class="font-normal-semibold">
            {{ t('coursefinder') }}
          </div>
          <nav role="navigation"
            [attr.aria-label]="t('aria_accredible_coursefinder_links')">
            <ul>
              <li><a href="https://help.accredible.com/what-is-coursefinder">{{ t('about_coursefinder') }}</a></li>
              <li><a href="https://help.accredible.com/coursefinder">{{ t('adding_your_courses') }}</a></li>
              <li><a href="https://help.accredible.com/coursefinder">{{ t('be_a_partner') }}</a></li>
            </ul>
          </nav>
        </div>
        <div>
          <div class="font-normal-semibold">
            {{ t('company') }}
          </div>
          <nav role="navigation"
            [attr.aria-label]="t('aria_accredible_company_links')">
            <ul>
              <li><a href="https://www.accredible.com/about">{{ t('about_accredible') }}</a></li>
              <li><a href="https://www.accredible.com/careers">{{ t('careers') }}</a></li>
              <li><a href="https://www.accredible.com/customers">{{ t('customers') }}</a></li>
              <li><a href="https://www.accredible.com/contact">{{ t('contact') }}</a></li>
              <li><a href="https://www.accredible.com/legal/privacy-policy">{{ t('data_security_privacy') }}</a></li>
              <li><a href="https://www.accredible.com/team">{{ t('our_team') }}</a></li>
              <li><a href="https://www.accredible.com/press">{{ t('press') }}</a></li>
              <li><a href="https://www.accredible.com/white_hat">{{ t('whitehat') }}</a></li>
              <li><a href="https://dashboard.accredible.com/login">{{ t('my_account') }}</a></li>
            </ul>
          </nav>
        </div>
      </div>
      <div>
        <div>
          <div class="font-normal-semibold">
            {{ t('resources') }}
          </div>
          <nav role="navigation"
            [attr.aria-label]="t('aria_accredible_resources_links')">
            <ul>
              <li><a href="https://help.accredible.com">{{ t('support') }}</a></li>
              <li><a href="https://www.accredible.com/blog">{{ t('blog') }}</a></li>
              <li><a href="https://www.accredible.com/case-studies">{{ t('case_studies') }}</a></li>
              <li><a href="https://www.accredible.com/downloadable-assets">{{ t('guides_and_checklists') }}</a></li>
              <li><a href="https://www.accredible.com/webinar">{{ t('webinars') }}</a></li>
              <li><a href="https://www.accredible.com/videos">{{ t('videos') }}</a></li>
              <li><a href="https://www.accredible.com/badge-designer">{{ t('badge_designer') }}</a></li>
              <li><a href="https://badge-case.study/case-studies">{{ t('badging_case_studies') }}</a></li>
            </ul>
          </nav>
        </div>
        <div>
          <div class="font-normal-semibold">
            {{ t('popular_links') }}
          </div>
          <nav role="navigation"
            [attr.aria-label]="t('aria_accredible_popular_links')">
            <ul>
              <li>
                <a href="https://www.accredible.com/solutions/digital-credentials">
                  {{ t('what_is_credential') }}
                </a>
              </li>
              <li><a href="https://www.accredible.com/downloadable-assets">{{ t('overview_brochures') }}</a></li>
              <li>
                <a href="https://www.credential.net/10000005?_ga=2.183752293.297716851.1643737889-938696959.1630414002">
                  {{ t('example_certificates') }}
                </a>
              </li>
              <li><a href="https://www.accredible.com/badge-designer">{{ t('free_badge_designer') }}</a></li>
              <li>
                <a href="https://www.accredible.com/downloadable-assets/download-accredibles-comprehensive-guide-to-digital-badges">
                  {{ t('guide_digital_badges') }}
                </a>
              </li>
              <li><a href="https://www.accredible.com/faq">{{ t('faqs') }}</a></li>
              <li><a href="https://www.accredible.com/data-security-privacy">{{ t('gdpr_compliance') }}</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom">
    <div class="links font-xs-regular">
      <ul>
        <li>
          <a href="https://www.accredible.com/legal/privacy-policy">
            {{ t('privacy_policy') }}
          </a>
        </li>
        <li>
          <a href="https://help.accredible.com/s/article/accessibility">
            {{ t('accessibility') }}
          </a>
        </li>
        <li>
          <a href="https://www.accredible.com/terms">
            {{ t('terms_of_service') }}
          </a>
        </li>
      </ul>
    </div>

    <div class="address">
      <div class="font-xs-semibold">
        Accredible ©2022
      </div>
      <div class="font-xs-regular">
        800 West El Camino Real, Suite 180, Accredible, Mountain View, CA, 94040
      </div>
    </div>
  </div>
</footer>
