import { AccrediblePageData, AccrediblePageParams } from '@accredible-frontend-v2/models';
import { createAction, props } from '@ngrx/store';
import { Course, CourseIssuer, CoursesParams } from '../../models/course.model';

// COURSES
export const loadCourses = createAction(
  '[Courses] Load Courses',
  props<{ coursesParams: CoursesParams }>(),
);
export const loadCoursesSuccess = createAction(
  '[Courses] Load Courses Success',
  props<{ coursesPageData: AccrediblePageData<Course> }>(),
);
export const loadCoursesFailure = createAction(
  '[Courses] Load Courses Failure',
  props<{ error: any }>(),
);

// TRENDING COURSES
export const loadTrendingCourses = createAction(
  '[Courses] Load Trending Courses',
  props<{ trendingCoursesParams: CoursesParams }>(),
);
export const loadTrendingCoursesSuccess = createAction(
  '[Courses] Load Trending Courses Success',
  props<{ trendingCourses: Course[] }>(),
);
export const loadTrendingCoursesFailure = createAction(
  '[Courses] Load Trending Courses Failure',
  props<{ error: any }>(),
);

// FAVORITE COURSES
export const loadFavoriteCourses = createAction(
  '[Courses] Load Favorite Courses',
  props<{ userId: number; pageParams: AccrediblePageParams; isInitialLoad?: boolean }>(),
);
export const loadFavoriteCoursesSuccess = createAction(
  '[Courses] Load Favorite Courses Success',
  props<{ favoriteCoursesPageData: AccrediblePageData<Course> }>(),
);
export const loadFavoriteCoursesFailure = createAction(
  '[Courses] Load Favorite Courses Failure',
  props<{ error: any }>(),
);

export const addFavoriteCourse = createAction(
  '[Courses] Add Favorite Course',
  props<{ userId: number; courseId: number; courseViewId: number }>(),
);
export const addFavoriteCourseSuccess = createAction(
  '[Courses] Add Favorite Course Success',
  props<{ courseId: number }>(),
);
export const addFavoriteCourseFailure = createAction(
  '[Courses] Add Favorite Course Failure',
  props<{ error: any }>(),
);

export const removeFavoriteCourse = createAction(
  '[Courses] Remove Favorite Course',
  props<{ userId: number; courseId: number; courseViewId: number }>(),
);
export const removeFavoriteCourseSuccess = createAction(
  '[Courses] Remove Favorite Course Success',
  props<{ courseId: number }>(),
);
export const removeFavoriteCourseFailure = createAction(
  '[Courses] Remove Favorite Course Failure',
  props<{ error: any }>(),
);

// PROVIDERS
export const loadProviders = createAction('[Courses] Load Providers', props<{ query?: string }>());
export const loadProvidersSuccess = createAction(
  '[Courses] Load Providers Success',
  props<{ query: string; providers: CourseIssuer[] }>(),
);
export const loadProvidersFailure = createAction(
  '[Courses] Load Providers Failure',
  props<{ error: any }>(),
);

export const loadSelectedProviders = createAction(
  '[Courses] Load Selected Providers',
  props<{ ids: number[] }>(),
);
export const loadSelectedProvidersSuccess = createAction(
  '[Courses] Load Selected Providers Success',
  props<{ selectedProviders: CourseIssuer[] }>(),
);
export const loadSelectedProvidersFailure = createAction(
  '[Courses] Load Selected Providers Failure',
  props<{ error: any }>(),
);

export const setSelectedProviders = createAction(
  '[Courses] Set Selected Providers',
  props<{ selectedProviders: CourseIssuer[] }>(),
);

// SKILLS
export const loadSkills = createAction('[Courses] Load Skills', props<{ query?: string }>());
export const loadSkillsSuccess = createAction(
  '[Courses] Load Skills Success',
  props<{ query: string; skills: string[] }>(),
);
export const loadSkillsFailure = createAction(
  '[Courses] Load Skills Failure',
  props<{ error: any }>(),
);

// LANGUAGES
export const loadLanguages = createAction('[Courses] Load Languages');
export const loadLanguagesSuccess = createAction(
  '[Courses] Load Languages Success',
  props<{ languages: string[] }>(),
);
export const loadLanguagesFailure = createAction(
  '[Courses] Load Languages Failure',
  props<{ error: any }>(),
);

export const resetFilters = createAction('[Courses] Reset Filters');

// RESET
export const resetValue = createAction('[Courses] Reset Value', props<{ name: string }>());
export const resetState = createAction('[Courses] Reset');
