import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AccredibleCheckboxInputComponent } from './checkbox-input.component';

@NgModule({
  imports: [ReactiveFormsModule, AccredibleIconComponentModule, CommonModule],
  declarations: [AccredibleCheckboxInputComponent],
  exports: [AccredibleCheckboxInputComponent],
})
export class AccredibleCheckboxInputComponentModule {}
