import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CopyToClipboardComponent } from './copy-to-clipboard.component';

@NgModule({
  imports: [CommonModule, AccredibleButtonComponentModule, ClipboardModule, TranslocoModule],
  exports: [CopyToClipboardComponent],
  declarations: [CopyToClipboardComponent],
  providers: [],
})
export class AccredibleCopyToClipboardComponentModule {}
