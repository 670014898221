import { environment } from '@accredible-frontend-v2/envs';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as CoursesStore from './courses/courses.reducer';
import { coursesFeatureKey } from './courses/courses.reducer';

export interface State {
  [coursesFeatureKey]: CoursesStore.CoursesState;
}

export const reducers: ActionReducerMap<State> = {
  [coursesFeatureKey]: CoursesStore.reducer,
};

export const metaReducers: MetaReducer<State>[] = environment.type !== 'production' ? [] : [];
