import { ButtonColor } from '@accredible-frontend-v2/new-components/button';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccredibleConfirmDialogData } from './models/confirm-dialog.model';

@Component({
  selector: 'accredible-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // we are using ViewEncapsulation.None in order to be able to style the overlay backdrop
  encapsulation: ViewEncapsulation.None,
})
export class AccredibleConfirmDialogComponent {
  @Input()
  confirmDialogTitle: string;
  @Input()
  confirmDialogText: string;
  @Input()
  confirmDialogImage: string;
  @Input()
  actionBtnText = this._language.translate('new-confirm-dialog.ok');
  @Input()
  actionBtnColor: ButtonColor = 'danger';
  @Input()
  cancelBtnText = this._language.translate('new-confirm-dialog.cancel');
  @Input()
  actionBtnDisabled = false;
  @Input()
  isLoading = false;
  @Input()
  checkboxText: string;

  @Output()
  actionBtnClicked = new EventEmitter<boolean>();

  checkboxFormControl = new FormControl<boolean>(false);

  constructor(
    @Inject(DIALOG_DATA)
    public readonly data: AccredibleConfirmDialogData,
    private readonly _dialogRef: DialogRef<boolean>,
    private readonly _language: AccredibleLanguageService,
  ) {
    if (!this.data) {
      return;
    }

    this.confirmDialogTitle = this.data.title;
    this.confirmDialogImage = this.data.image;
    this.confirmDialogText = this.data.text;
    this.actionBtnText = this.data.btnText || this.actionBtnText;
    this.actionBtnColor = this.data.btnColor || this.actionBtnColor;
    this.checkboxText = this.data.checkboxText || this.checkboxText;
    // When using dialog.service.ts confirm method to open this dialog,
    // if you want to hide the cancel button you can send null or '',
    // if you want the default "Cancel" text you should send nothing (undefined)
    this.cancelBtnText =
      this.data.cancelBtnText === undefined ? this.cancelBtnText : this.data.cancelBtnText || null;
  }

  confirm(): void {
    if (this.data?.title) {
      this._dialogRef.close(true);
    } else {
      this.actionBtnClicked.emit(true);
    }
  }

  cancel(): void {
    this._dialogRef.close(false);
  }
}
