import { AccredibleKey } from '@accredible-frontend-v2/utils/key-enum';
import { FocusKeyManager } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccredibleMenuItemComponent } from './components/menu-item.component';

@Component({
  selector: 'accredible-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleMenuComponent implements OnDestroy, AfterContentInit {
  readonly closed$ = new Subject<void>();
  readonly isOpened$ = new BehaviorSubject<boolean>(false);

  @Input()
  customClass = '';
  @Input()
  trapFocus = true;
  @Input()
  closeMenuOnClick = true;

  @ContentChildren(AccredibleMenuItemComponent)
  items: QueryList<AccredibleMenuItemComponent>;

  @ViewChild(TemplateRef)
  templateRef: TemplateRef<any>;

  private _keyManager: FocusKeyManager<AccredibleMenuItemComponent>;

  ngAfterContentInit(): void {
    this._keyManager = new FocusKeyManager(this.items).withWrap();
  }

  ngOnDestroy(): void {
    this.closed$.complete();
  }

  closeMenu(): void {
    if (!this.closeMenuOnClick) {
      return;
    }

    this.closed$.next();
  }

  keyHandler(event: KeyboardEvent): void {
    if (!this.closeMenuOnClick) {
      return;
    }

    switch (event.code) {
      case AccredibleKey.ESCAPE:
      case AccredibleKey.SPACE:
      case AccredibleKey.ENTER:
        this.closeMenu();
        break;
      default:
        this._keyManager.onKeydown(event);
        break;
    }
  }
}
