import { AccredibleCredentialAppearanceComponentModule } from '@accredible-frontend-v2/components/credential-appearance';
import { AccredibleSlideItemComponentModule } from '@accredible-frontend-v2/new-components/sliders/slide-item';
import { AccredibleSliderMultiComponentModule } from '@accredible-frontend-v2/new-components/sliders/slider-multi';
import { AccredibleTileComponentModule } from '@accredible-frontend-v2/new-components/tile';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { FavoriteComponentModule } from '../../../shared/components/favorite/favorite.component.module';
import { FeaturedModule } from '../../../shared/components/featured/featured.module';
import { ShareComponentModule } from '../../../shared/components/share/share.component.module';
import { TrendingCoursesComponent } from './trending-courses.component';

@NgModule({
  declarations: [TrendingCoursesComponent],
  imports: [
    CommonModule,
    FavoriteComponentModule,
    ShareComponentModule,
    AccredibleSliderMultiComponentModule,
    AccredibleSlideItemComponentModule,
    AccredibleCredentialAppearanceComponentModule,
    AccredibleTileComponentModule,
    TranslocoModule,
    FeaturedModule,
  ],
  exports: [TrendingCoursesComponent],
})
export class TrendingCoursesModule {}
