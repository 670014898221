import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleUser } from '@accredible-frontend-v2/models';
import {
  AccountsRedirectionKey,
  AccredibleAccountsRedirectionService,
} from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'cf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  readonly user$: Observable<AccredibleUser> = this._userApi.user$;

  readonly accountsLoginUrl = this._accountsRedirection.getLoginUrlHref('coursefinder');
  readonly accountsPersonalInformationUrl =
    this._accountsRedirection.getPersonalInformationUrl('coursefinder');
  readonly helpUrl = this._language.selectTranslate('language.help_links.help_center');
  myCredentialsUrl: string;

  constructor(
    private readonly _userApi: AccredibleUserApiService,
    private readonly _accountsRedirection: AccredibleAccountsRedirectionService,
    private readonly _authenticationApi: AccredibleAuthenticationApiService,
    private readonly _cookies: AccredibleCookiesService,
    private readonly _language: AccredibleLanguageService,
  ) {}

  ngOnInit(): void {
    if (this._userApi.isUserSignedIn()) {
      this.myCredentialsUrl = this._getRecipientPortalWalletUrl();
    }
  }

  logout(): void {
    this._authenticationApi.logout().subscribe({
      next: () => {},
      error: () => {
        // TODO(Fred): Ask Ahmed what to show if sign out fails. Should we implement a new toast service not using material?
        // this._toast.error(this._language.translate(TranslationKey.LOGOUT_FAILED));
      },
    });
  }

  /**
   * This method return the Recipient Portal URL with all the required search params.
   */
  private _getRecipientPortalWalletUrl(): string {
    const token = this._cookies.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
    return `${environment.credentialNetUrl}/profile/${
      this._userApi.getUser().username
    }/wallet?token=${token}`;
  }
}
