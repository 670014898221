<div *transloco="let t; read: 'nc-copy-to-clipboard'"
  class="copy-to-clipboard"
  [class.disabled]="disabled">
  <input type="text"
    readonly
    [value]="value"
    [attr.aria-labelledby]="labelId || null"
    [attr.data-cy]="dataCy">

  <div class="divider"></div>

  <ng-container [ngSwitch]="buttonType">
    <ng-container *ngSwitchCase="buttonTypes.FLAT">
      <button accredible-button-flat
        type="button"
        [icon]="'copy'"
        [id]="copyButtonId"
        [cdkCopyToClipboard]="value"
        [attr.aria-label]="t('copy_to_clipboard')"
        [disabled]="disabled"
        (click)="copyToClipboard()">
          <span aria-hidden="true">
            {{ copyButtonText }}
          </span>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="buttonTypes.NORMAL">
      <button accredible-button
        type="button"
        [icon]="'copy'"
        [id]="copyButtonId"
        [cdkCopyToClipboard]="value"
        [attr.aria-label]="t('copy_to_clipboard')"
        [disabled]="disabled"
        (click)="copyToClipboard()">
          <span aria-hidden="true">
            {{ copyButtonText }}
          </span>
      </button>
    </ng-container>
  </ng-container>
</div>
