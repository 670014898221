<ng-container *transloco="let t; read: 'slider-multi'">
  <div class="header">
    <div *ngIf="title"
      class="font-h-extrabold">
      {{ title }}
    </div>

    <div class="controls-container">
      <button accredible-button-icon
        [attr.aria-label]="t('aria_prev')"
        [size]="'small'"
        [color]="'natural'"
        [icon]="'chevron-left'"
        [iconSize]="'8px'"
        [disabled]="slider && isBeginning"
        (click)="previous()">
      </button>

      <button accredible-button-icon
        [attr.aria-label]="t('aria_next')"
        [size]="'small'"
        [color]="'natural'"
        [icon]="'chevron-right'"
        [iconSize]="'8px'"
        [disabled]="slider && isEnd"
        (click)="next()">
      </button>
    </div>
  </div>

  <accredible-base-slider [options]="sliderOptions"
    (swiperRef)="updateSwiperReference($event)">
    <accredible-slide-item *ngFor="let slide of slideItems">
      <ng-template *ngTemplateOutlet="slide.data?.template; context: { $implicit: slide.data?.slideData }">
      </ng-template>
    </accredible-slide-item>
  </accredible-base-slider>
</ng-container>
