import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { EmailShareButtonComponent } from './email-share-button/email-share-button.component';
import { FacebookShareButtonComponent } from './facebook-share-button/facebook-share-button.component';
import { LinkedInShareButtonComponent } from './linkedin-share-button/linkedin-share-button.component';
import { SocialShareButtonsComponent } from './social-share-buttons.component';
import { TwitterShareButtonComponent } from './twitter-share-button/twitter-share-button.component';
import { WhatsAppShareButtonComponent } from './whatsapp-share-button/whatsapp-share-button.component';

@NgModule({
  imports: [AccredibleIconComponentModule, TranslocoModule],
  declarations: [
    SocialShareButtonsComponent,
    EmailShareButtonComponent,
    FacebookShareButtonComponent,
    LinkedInShareButtonComponent,
    TwitterShareButtonComponent,
    WhatsAppShareButtonComponent,
  ],
  exports: [SocialShareButtonsComponent],
})
export class AccredibleSocialShareButtonsModule {}
