<div class="version">{{ version }}</div>

<div class="coursefinder-container">
  <cf-header></cf-header>

  <router-outlet></router-outlet>

  <cf-trending-courses *ngIf="(trendingCourses$ | async).length > 0"
    [courses]="trendingCourses$ | async"
    [isLoading]="isLoadingTrendingCourses">
  </cf-trending-courses>

  <!-- Mobile articles -->
  <div *ngIf="!isDesktop"
    class="cf-articles">
    <cf-featured-read></cf-featured-read>
    <cf-featured-video></cf-featured-video>
    <cf-blog-article></cf-blog-article>
  </div>

  <cf-footer></cf-footer>
</div>

<accredible-cookies-banner></accredible-cookies-banner>
