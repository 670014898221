import { AccrediblePageData } from '@accredible-frontend-v2/models';
import { Action, createReducer, on } from '@ngrx/store';
import { Course, CourseIssuer, CoursesParams } from '../../models/course.model';
import * as CoursesActions from './courses.actions';
import { toggleFavoriteOnCoursesArrays } from './courses.helper';

export const coursesFeatureKey = 'courses';

export interface CoursesState {
  coursesPageData: AccrediblePageData<Course>;
  coursesParams: CoursesParams;
  trendingCourses: Course[];
  trendingCoursesParams: CoursesParams;
  favoriteCoursesPageData: AccrediblePageData<Course>;
  providers: CourseIssuer[];
  selectedProviders: CourseIssuer[];
  skills: string[];
  languages: string[];

  action: CoursesStateAction;
  payload: unknown;
  error: unknown;

  [key: string]: unknown;
}

export enum CoursesStateAction {
  NO_ACTION,

  COURSES_LOADED,
  COURSES_ERROR,

  TRENDING_COURSES_LOADED,
  TRENDING_COURSES_ERROR,

  FAVORITE_COURSES_LOADED,
  FAVORITE_COURSES_ERROR,
  FAVORITE_COURSE_ADDED,
  FAVORITE_COURSE_REMOVED,

  PROVIDERS_LOADED,
  SELECTED_PROVIDERS_LOADED,
  SKILLS_LOADED,
  LANGUAGES_LOADED,

  HAS_ERROR,
}

const initialStateHandling: Partial<CoursesState> = {
  action: CoursesStateAction.NO_ACTION,
  payload: null,
  error: null,
};

const ghostCourse = <Course>{
  course_finder_category: null,
  learning_outcomes: ['ghost', 'ghost'],
  issuer: {},
};
const ghostResults = [ghostCourse, ghostCourse, ghostCourse, ghostCourse, ghostCourse];

export const initialState = <CoursesState>{
  coursesPageData: {
    meta: {},
    results: ghostResults,
  },
  coursesParams: <CoursesParams>{
    page: 1,
    limit: 10,
    query: '',
    course_finder_categories: [],
    providers: [],
    skills: [],
    languages: [],
    featured_limit: 3,
  },
  trendingCourses: ghostResults,
  trendingCoursesParams: {
    limit: 10,
    featured_limit: 2,
  },
  favoriteCoursesPageData: {
    meta: {},
    results: ghostResults,
  },
  // Hardcoded providers are used in order to give the user better suggestions at first (these are production ids)
  providers: [
    { id: 53207, name: 'ASQ Certification' },
    { id: 18374, name: 'Automation Anywhere' },
    { id: 26252, name: 'Center for Creative Leadership' },
    { id: 35494, name: 'CG Spectrum College of Digital Art & Animation' },
    { id: 17564, name: 'CFP Board' },
    { id: 17814, name: 'CXL' },
    { id: 104, name: 'Digital Marketing Institute' },
    { id: 34211, name: 'Elastic' },
    { id: 293, name: 'Hootsuite' },
    { id: 24840, name: 'International Institute of Business Analysis™' },
    { id: 669, name: 'IDEO U' },
    { id: 24222, name: 'Maven Analytics' },
    { id: 29566, name: 'Mountain Goat Software' },
    { id: 10786, name: 'Packt' },
    { id: 11497, name: 'PowerObjects, An HCL Technologies Company' },
    { id: 663, name: 'Product School' },
    { id: 51991, name: 'Slack' },
    { id: 24223, name: 'Skilling America' },
    { id: 11983, name: 'SuccessCOACHING' },
    { id: 35989, name: 'Wellingtone' },
  ],
  selectedProviders: [],
  // Hardcoded skills are used in order to give the user better suggestions at first
  skills: [
    'Adobe XD',
    'Agile',
    'AI',
    'Business Analysis',
    'Blockchain',
    'CRM',
    'Cyber Security',
    'Financial Planning',
    'Google Cloud Computing',
    'Leadership and Motivation',
    'Machine Learning',
    'Medical Assistant',
    'Pediatric Emergency Care',
    'Personal Branding',
    'Process Improvement',
    'Project Management',
    'Product Management',
    'Search Engine Optimization',
    'Test Automation',
    'Workplace Safety',
  ],
  languages: [],

  ...initialStateHandling,
};

const coursesReducer = createReducer(
  initialState,
  // COURSES
  on(CoursesActions.loadCourses, (state, { coursesParams }) => ({
    ...state,
    coursesPageData: <AccrediblePageData<Course>>{
      meta: <unknown>state.coursesPageData.meta,
      results: ghostResults,
    },
    coursesParams,
    ...initialStateHandling,
  })),
  on(CoursesActions.loadCoursesSuccess, (state, { coursesPageData }) => ({
    ...state,
    coursesPageData: <AccrediblePageData<Course>>{
      ...coursesPageData,
      meta: {
        ...coursesPageData.meta,
        // The FE sends a limit=10 and a featured_limit=3, this means we want to see a total of 10 courses and within these 10 courses, 3 of them should be featured.
        // In order to make this logic work, the BE limit changes to 7 plus 3 featured courses. Because the 'meta' object is built automatically on the BE it doesn't know about the 3 featured courses being added on every page.
        // Therefore, we have to recalculate the total_count and add the featured_limit number per page.
        total_count:
          coursesPageData.meta.total_count +
          coursesPageData.meta.total_pages * state.coursesParams.featured_limit,
      },
    },
    action: CoursesStateAction.COURSES_LOADED,
  })),
  on(CoursesActions.loadCoursesFailure, (state) => ({
    ...state,
    // We need to set results as an empty array, otherwise ghosts would show after error
    coursesPageData: <AccrediblePageData<Course>>{ meta: <unknown>{}, results: [] },
    action: CoursesStateAction.COURSES_ERROR,
  })),

  // TRENDING COURSES
  on(CoursesActions.loadTrendingCourses, (state) => ({
    ...state,
    trendingCourses: initialState.trendingCourses,
    ...initialStateHandling,
  })),
  on(CoursesActions.loadTrendingCoursesSuccess, (state, { trendingCourses }) => ({
    ...state,
    trendingCourses,
    action: CoursesStateAction.TRENDING_COURSES_LOADED,
  })),
  on(CoursesActions.loadTrendingCoursesFailure, (state) => ({
    ...state,
    action: CoursesStateAction.TRENDING_COURSES_ERROR,
  })),

  // FAVORITE COURSES
  on(CoursesActions.loadFavoriteCourses, (state, { isInitialLoad }) => ({
    ...state,
    favoriteCoursesPageData: <AccrediblePageData<Course>>{
      meta: <unknown>state.favoriteCoursesPageData.meta,
      results: isInitialLoad ? ghostResults : state.favoriteCoursesPageData.results,
    },
    ...initialStateHandling,
  })),
  on(CoursesActions.loadFavoriteCoursesSuccess, (state, { favoriteCoursesPageData }) => ({
    ...state,
    favoriteCoursesPageData,
    action: CoursesStateAction.FAVORITE_COURSES_LOADED,
  })),
  on(CoursesActions.loadFavoriteCoursesFailure, (state) => ({
    ...state,
    // We need to set results as an empty array, otherwise ghosts would show after error
    favoriteCoursesPageData: <AccrediblePageData<Course>>{ meta: <unknown>{}, results: [] },
    action: CoursesStateAction.FAVORITE_COURSES_ERROR,
  })),

  on(CoursesActions.addFavoriteCourse, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CoursesActions.addFavoriteCourseSuccess, (state, { courseId }) => {
    const [results, trendingCourses] = toggleFavoriteOnCoursesArrays(courseId, [
      state.coursesPageData.results,
      state.trendingCourses,
    ]);

    return {
      ...state,
      coursesPageData: <AccrediblePageData<Course>>{
        results,
        meta: <unknown>state.coursesPageData.meta,
      },
      trendingCourses,
      // favoriteCoursesPageData: we don't update favoriteCoursesPageData because we always load them from the BE after any update
      action: CoursesStateAction.FAVORITE_COURSE_ADDED,
    };
  }),
  on(CoursesActions.addFavoriteCourseFailure, (state) => ({
    ...state,
    action: CoursesStateAction.HAS_ERROR,
  })),

  on(CoursesActions.removeFavoriteCourse, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(CoursesActions.removeFavoriteCourseSuccess, (state, { courseId }) => {
    const [results, trendingCourses] = toggleFavoriteOnCoursesArrays(courseId, [
      state.coursesPageData.results,
      state.trendingCourses,
    ]);
    return {
      ...state,
      coursesPageData: <AccrediblePageData<Course>>{
        results,
        meta: <unknown>state.coursesPageData.meta,
      },
      trendingCourses,
      // favoriteCoursesPageData: we don't update favoriteCoursesPageData because we always load them from the BE after any update
      action: CoursesStateAction.FAVORITE_COURSE_REMOVED,
    };
  }),
  on(CoursesActions.removeFavoriteCourseFailure, (state) => ({
    ...state,
    action: CoursesStateAction.HAS_ERROR,
  })),

  // PROVIDERS
  on(CoursesActions.loadProviders, (state) => ({
    ...state,
  })),
  on(CoursesActions.loadProvidersSuccess, (state, { query, providers }) => {
    return {
      ...state,
      providers: !query ? initialState.providers : providers,
      action: CoursesStateAction.PROVIDERS_LOADED,
    };
  }),
  on(CoursesActions.loadProvidersFailure, (state) => ({
    ...state,
    action: CoursesStateAction.HAS_ERROR,
  })),

  on(CoursesActions.loadSelectedProviders, (state) => ({
    ...state,
  })),
  on(CoursesActions.loadSelectedProvidersSuccess, (state, { selectedProviders }) => ({
    ...state,
    selectedProviders,
    action: CoursesStateAction.SELECTED_PROVIDERS_LOADED,
  })),
  on(CoursesActions.loadSelectedProvidersFailure, (state) => ({
    ...state,
    action: CoursesStateAction.HAS_ERROR,
  })),

  on(CoursesActions.setSelectedProviders, (state, { selectedProviders }) => ({
    ...state,
    selectedProviders,
  })),

  // SKILLS
  on(CoursesActions.loadSkills, (state) => ({
    ...state,
  })),
  on(CoursesActions.loadSkillsSuccess, (state, { query, skills }) => {
    return {
      ...state,
      skills: !query ? initialState.skills : skills,
      action: CoursesStateAction.SKILLS_LOADED,
    };
  }),
  on(CoursesActions.loadSkillsFailure, (state) => ({
    ...state,
    action: CoursesStateAction.HAS_ERROR,
  })),

  // LANGUAGES
  on(CoursesActions.loadLanguages, (state) => ({
    ...state,
  })),
  on(CoursesActions.loadLanguagesSuccess, (state, { languages }) => ({
    ...state,
    languages,
    action: CoursesStateAction.LANGUAGES_LOADED,
  })),

  // RESET
  on(CoursesActions.resetFilters, () => ({ ...initialState })),
  on(CoursesActions.resetValue, (state, { name }) => ({
    ...state,
    ...initialStateHandling,
    [name]: initialState[name],
  })),
  on(CoursesActions.resetState, () => initialState),
);

export function reducer(state: CoursesState, action: Action): CoursesState {
  return coursesReducer(state, action);
}
