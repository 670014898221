import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../containers/search-results/search-results.container.module').then(
        (m) => m.SearchResultsContainerModule,
      ),
  },
  {
    path: 'favorite-courses',
    loadChildren: () =>
      import('../containers/favorite-courses/favorite-courses.container.module').then(
        (m) => m.FavoriteCoursesContainerModule,
      ),
  },
  {
    path: 'tell-us-what-you-want',
    loadChildren: () =>
      import('../containers/tell-us/tell-us.container.module').then((m) => m.TellUsContainerModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class CourseFinderRoutingModule {}
