import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import * as CoursesActions from './courses.actions';
import { CoursesService } from './courses.service';

@Injectable()
export class CoursesEffects {
  searchCourses = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.loadCourses),
      switchMap((action) =>
        this._coursesService.searchCourses(action.coursesParams).pipe(
          map((coursesPageData) => CoursesActions.loadCoursesSuccess({ coursesPageData })),
          catchError((error) => of(CoursesActions.loadCoursesFailure({ error }))),
        ),
      ),
    ),
  );

  loadTrendingCourses = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.loadTrendingCourses),
      exhaustMap((action) =>
        this._coursesService.loadTrendingCourses(action.trendingCoursesParams).pipe(
          map((trendingCourses) => CoursesActions.loadTrendingCoursesSuccess({ trendingCourses })),
          catchError((error) => of(CoursesActions.loadTrendingCoursesFailure({ error }))),
        ),
      ),
    ),
  );

  loadFavoriteCourses = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.loadFavoriteCourses),
      switchMap((action) =>
        this._coursesService.loadFavoriteCourses(action.userId, action.pageParams).pipe(
          map((favoriteCoursesPageData) =>
            CoursesActions.loadFavoriteCoursesSuccess({ favoriteCoursesPageData }),
          ),
          catchError((error) => of(CoursesActions.loadFavoriteCoursesFailure({ error }))),
        ),
      ),
    ),
  );

  addFavoriteCourse = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.addFavoriteCourse),
      switchMap((action) =>
        this._coursesService
          .addFavoriteCourse(action.userId, action.courseId, action.courseViewId)
          .pipe(
            map(() => CoursesActions.addFavoriteCourseSuccess({ courseId: action.courseId })),
            catchError((error) => of(CoursesActions.addFavoriteCourseFailure({ error }))),
          ),
      ),
    ),
  );

  removeFavoriteCourse = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.removeFavoriteCourse),
      switchMap((action) =>
        this._coursesService
          .removeFavoriteCourse(action.userId, action.courseId, action.courseViewId)
          .pipe(
            map(() => CoursesActions.removeFavoriteCourseSuccess({ courseId: action.courseId })),
            catchError((error) => of(CoursesActions.removeFavoriteCourseFailure({ error }))),
          ),
      ),
    ),
  );

  loadProviders = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.loadProviders),
      switchMap((action) =>
        this._coursesService.loadProviders(action.query).pipe(
          map((providers) =>
            CoursesActions.loadProvidersSuccess({ query: action.query, providers }),
          ),
          catchError((error) => of(CoursesActions.loadProvidersFailure({ error }))),
        ),
      ),
    ),
  );

  loadSelectedProviders = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.loadSelectedProviders),
      switchMap((action) =>
        this._coursesService.loadSelectedProviders(action.ids).pipe(
          map((selectedProviders) =>
            CoursesActions.loadSelectedProvidersSuccess({ selectedProviders }),
          ),
          catchError((error) => of(CoursesActions.loadSelectedProvidersFailure({ error }))),
        ),
      ),
    ),
  );

  loadSkills = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.loadSkills),
      switchMap((action) =>
        this._coursesService.loadSkills(action.query).pipe(
          map((skills) => CoursesActions.loadSkillsSuccess({ query: action.query, skills })),
          catchError((error) => of(CoursesActions.loadSkillsFailure({ error }))),
        ),
      ),
    ),
  );

  loadLanguages = createEffect(() =>
    this._actions$.pipe(
      ofType(CoursesActions.loadLanguages),
      exhaustMap(() =>
        this._coursesService.loadLanguages().pipe(
          map((languages) => CoursesActions.loadLanguagesSuccess({ languages })),
          catchError((error) => of(CoursesActions.loadLanguagesFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _coursesService: CoursesService,
  ) {}
}
