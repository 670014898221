<accredible-dialog-template *transloco="let t; read: 'sign-in-dialog'">
  <div class="content">
    <img alt=""
      src="assets/images/sign-in.svg">

    <p class="font-l-semibold">{{ t('paragraph') }}</p>

    <div class="buttons-container">
      <button accredible-button-flat
        color="natural"
        (keydown.space)="close()"
        (keydown.enter)="close()"
        (click)="close()">
        {{ t('cancel') }}
      </button>

      <button accredible-button
        (keydown.space)="signIn()"
        (keydown.enter)="signIn()"
        (click)="signIn()">
        {{ t('sign_in') }}
      </button>
    </div>
  </div>
</accredible-dialog-template>
