import { Course, LocalCourse } from '../../models/course.model';

// Languages conversion from BE to UI and vice-versa, in order to not differentiate between similar languages
//  UI | ------------------ | BE
// 'en'| <----- merge ----- | ['en','en-gb']
// 'en'| ------ fork -----> | ['en','en-gb']
export const LANGUAGES_CONVERSION = {
  merge: {
    // English
    en: { value: 'en', name: 'English' },
    'en-gb': { value: 'en', name: 'English' },
    // Spanish
    'es-419': { value: 'es', name: 'Español' },
    es: { value: 'es', name: 'Español' },
    // Portuguese
    'pt-pt': { value: 'pt', name: 'Português' },
    'pt-br': { value: 'pt', name: 'Português' },
  },
  fork: {
    es: ['es', 'es-419'],
    pt: ['pt-pt', 'pt-br'],
    en: ['en', 'en-gb'],
  },
};

/**
 * We need to map a Course into a LocalCourse,
 * in order to use the partialCredential on the accredible-credential-appearance component
 *
 * @param course
 * @param featuredLimit - number of featured courses to show on the UI
 * @param index - index of the course in the array that is being mapped
 * @param hasFeatured - if the array that is being mapped has featured recommended courses
 */
export const mapCourseToLocalCourse = (
  course: Course,
  featuredLimit?: number,
  index = 0,
  hasFeatured = false,
): LocalCourse => {
  return <LocalCourse>{
    ...course,
    // The API always returns the key recommendation_featured.
    // This key is a boolean that says if the course/group is a featured recommended course.
    // But our logic in CourseFinder is that only search results should show featured courses/groups and only in the {featuredLimit} first positions of the list,
    // so the {featuredLimit} first results that come from the API should always be featured recommended courses but none of the others,
    // so we have to manually set to false all the others because they could be true based on the recommendations' engine.
    recommendation_featured: hasFeatured
      ? index >= featuredLimit
        ? false
        : course.recommendation_featured
      : false,
    partialCredential: {
      group: {
        course_name: course.course_name,
        course_description: course.course_description,
        course_link: course.course_link,
        learning_outcomes: course.learning_outcomes,
        certificate_design: course.certificate_design,
        badge_design: course.badge_design,
        primary_design_id: course.primary_design_id,
      },
    },
  };
};

export const toggleFavoriteOnCoursesArrays = (
  courseId: number,
  arraysToIterate: Course[][],
): Course[][] => {
  let arrayOfMaps: Map<number, Course>[] = [];
  let arrayOfCoursesArray: Course[][] = [];

  // Convert arrays to maps
  for (let currentArray of arraysToIterate) {
    arrayOfMaps.push(new Map(currentArray.map((course) => [course.id, course])));
  }

  // Mark the favorite on all maps
  for (let courseMap of arrayOfMaps) {
    const course = courseMap.get(courseId);
    if (course) {
      if (course.favorited_by_current_user) {
        courseMap.set(courseId, {
          ...course,
          favorited_by_current_user: false,
          user_favorite_groups_count: course.user_favorite_groups_count - 1,
        });
      } else {
        courseMap.set(courseId, {
          ...course,
          favorited_by_current_user: true,
          user_favorite_groups_count: course.user_favorite_groups_count + 1,
        });
      }
    }
  }

  // Convert maps back into arrays
  for (let courseMap of arrayOfMaps) {
    arrayOfCoursesArray.push(Array.from(courseMap.values()));
  }

  return arrayOfCoursesArray;
};
