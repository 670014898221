import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'accredible-social-share-buttons',
  templateUrl: './social-share-buttons.component.html',
  styleUrls: ['./social-share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialShareButtonsComponent {
  @Input()
  url = this._document.location.href;
  @Input()
  text: string;
  @Input()
  emailText: string;

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}
}
