import { AccredibleCheckboxInputComponentModule } from '@accredible-frontend-v2/new-components/checkbox-input';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleDialogTemplateComponentModule } from '../dialog-template/dialog-template.component.module';
import { AccredibleConfirmDialogComponent } from './confirm-dialog.component';

@NgModule({
  declarations: [AccredibleConfirmDialogComponent],
  imports: [
    CommonModule,
    AccredibleDialogTemplateComponentModule,
    AccredibleCheckboxInputComponentModule,
  ],
  exports: [AccredibleConfirmDialogComponent],
})
export class AccredibleConfirmDialogComponentModule {}
