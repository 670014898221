import { AccredibleDialogService } from '@accredible-frontend-v2/new-components/dialog';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Course } from '../../../core/models/course.model';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';

@Component({
  selector: 'cf-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareComponent {
  @Input()
  isLoading = true;
  @Input()
  course: Course;

  constructor(private readonly _dialogService: AccredibleDialogService) {}

  openShareDialog(event: Event): void {
    event.preventDefault();
    this._dialogService.open(ShareDialogComponent, {
      data: {
        course: this.course,
      },
    });
  }
}
