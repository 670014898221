<a *transloco="let t; read: 'featured-read'"
  id="trkFeaturedReadLink"
  href="https://www.indeed.com/career-advice/career-development/certifications-in-demand"
  target="_blank"
  [attr.aria-label]="t('aria_featured_read')">
  <div class="featured-read-image"></div>

  <div class="bottom">
    <div class="font-s-semibold">{{ t('featured_read') }}</div>
    <h2 class="font-l-semibold">{{ t('title') }}</h2>

    <a accredible-link
      id="trkFeaturedReadLinkIn"
      href="https://www.indeed.com/career-advice/career-development/certifications-in-demand"
      target="_blank"
      [color]="'light'">
      {{ t('see_the_list') }}
    </a>
  </div>
</a>
