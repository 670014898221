import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';

export const shareInPopup = (socialShareUrl: string): void => {
  const popupWidth = 700;
  const popupHeight = 500;
  const left = (WindowHelper.window.innerWidth - popupWidth) / 2;
  const top = (WindowHelper.window.innerHeight - popupHeight) / 2;
  WindowHelper.window.open(
    socialShareUrl,
    '_blank',
    `menubar=1,width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
  );
};
