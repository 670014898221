import { AccredibleSlideItemComponent } from '@accredible-frontend-v2/new-components/sliders/slide-item';
import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { Swiper, SwiperOptions } from 'swiper';

@Component({
  selector: 'accredible-base-slider',
  templateUrl: './base-slider.component.html',
  styleUrls: ['./base-slider.component.scss'],
  // ViewEncapsulation.None is needed in order for the swiper.min.css to be applied
  encapsulation: ViewEncapsulation.None,
  // TODO(Fred): ChangeDetectionStrategy.OnPush can't be used in order for the certificates to be correctly drawn on any slider using the certificate/badge components.
  //  But, this logic will not be needed when we start using thumbnail images instead of the certificate/badge components.
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleBaseSliderComponent {
  @Input()
  options: SwiperOptions;

  @Output()
  swiperRef = new EventEmitter<Swiper>();

  @ContentChildren(AccredibleSlideItemComponent, { descendants: true })
  slideItems: QueryList<AccredibleSlideItemComponent>;

  onSwiper(swiper: Swiper): void {
    this.swiperRef.emit(swiper);
  }
}
