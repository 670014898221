import { AccredibleToastService } from '@accredible-frontend-v2/new-components/toast';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: [`./copy-to-clipboard.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent {
  @Input()
  value: string;
  @Input()
  message: string;
  @Input()
  labelId: string;
  @Input()
  copyButtonText = this._language.translate('nc-copy-to-clipboard.copy');
  @Input()
  copyButtonId: string | null = null;
  @Input()
  buttonType: 'flat' | 'normal' = 'flat';
  @Input()
  dataCy = 'input-copy';
  @Input()
  disabled = false;

  buttonTypes = {
    FLAT: 'flat',
    NORMAL: 'normal',
  };

  constructor(
    private readonly _toast: AccredibleToastService,
    private readonly _language: AccredibleLanguageService,
  ) {}

  copyToClipboard(): void {
    this._toast.info(this.message);
  }
}
