import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Course, LocalCourse } from '../../models/course.model';

@Component({
  selector: 'cf-trending-courses',
  templateUrl: './trending-courses.component.html',
  styleUrls: ['./trending-courses.component.scss'],
  // ViewEncapsulation.None in order to be able to style swiper without using ::ng-deep
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendingCoursesComponent {
  @Input()
  title: string;
  @Input()
  courses: LocalCourse[];
  @Input()
  isLoading = true;

  isTabletOrMobile = this._deviceDetector.isTablet() || this._deviceDetector.isMobile();

  constructor(private readonly _deviceDetector: DeviceDetectorService) {}

  trackById(index: number, course: Course): number {
    return course.id;
  }
}
