<accredible-dialog-template *transloco="let t; read: 'cf-share-dialog'"
  class="cf-share-dialog"
  [title]="t('share_course', { courseName: data.course.course_name })">

  <accredible-copy-to-clipboard [value]="data.course.course_link"
    [message]="t('course_copied')"
    [copyButtonId]="'trkCopyURLShareButton'">
  </accredible-copy-to-clipboard>

  <div class="divider"></div>

  <div class="social-buttons">
    <span class="font-normal-semibold">{{ t('share') }}</span>
    <accredible-social-share-buttons [url]="data.course.course_link"
      [text]="t('share_text')"
      [emailText]="t('share_email_text')">
    </accredible-social-share-buttons>
  </div>

</accredible-dialog-template>
