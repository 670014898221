<accredible-dialog-template [actionBtnText]="actionBtnText"
  [cancelBtnText]="cancelBtnText"
  [actionBtnColor]="actionBtnColor"
  [centered]="true"
  [actionBtnDisabled]="actionBtnDisabled || (checkboxText ? !checkboxFormControl?.value : false)"
  [isLoading]="isLoading"
  (actionBtnClicked)="confirm()">
  <div class="confirm-dialog">
    <img *ngIf="confirmDialogImage"
      [src]="confirmDialogImage"
      alt="">

    <h1 class="font-l-semibold">
      {{ confirmDialogTitle }}
    </h1>

    <div class="confirm-dialog-content">
      <div *ngIf="confirmDialogText"
        [innerHTML]="confirmDialogText">
      </div>

      <div *ngIf="checkboxText"
        class="checkbox-wrapper">
        <accredible-checkbox-input data-cy="input-confirm-dialog-checkbox"
          [formControlRef]="checkboxFormControl"
          [label]="checkboxText"
          [showFullText]="true">
        </accredible-checkbox-input>
      </div>

      <ng-content></ng-content>
    </div>
  </div>
</accredible-dialog-template>
