import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Directive({
  selector: 'accredible-tile-header',
  host: { class: 'accredible-tile-header' },
})
export class TileHeaderDirective {}

@Directive({
  selector: '[accredible-tile-title]',
  host: { class: 'font-normal-semibold' },
})
export class TileTitleDirective {}

@Directive({
  selector: 'accredible-tile-content',
  host: { class: 'accredible-tile-content' },
})
export class TileContentDirective {}

@Component({
  selector: 'accredible-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['tile.component.scss'],
  // ViewEncapsulation.None in to be able to style nested components without using ::ng-deep
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleTileComponent {
  @Input()
  hasHover = false;
  @Input()
  active = false;

  @HostBinding('class')
  get classes(): string {
    return this.active ? 'active' : this.hasHover ? 'has-hover' : '';
  }
}
