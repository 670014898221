<accredible-tile *transloco="let t; read: 'featured-video'">
  <a id="trkFeaturedVideoLink"
    href="https://www.accredible.com/blog/how-to-use-your-digital-credential"
    target="_blank"
    [attr.aria-label]="t('aria_featured_video')">
    <accredible-tile-content>
      <div class="content">
        <div class="featured-video-text font-s-semibold">{{ t('featured_video') }}</div>
        <img src="assets/images/featured_video.jpg"
          alt="">
        <h2 class="font-l-semibold">{{ t('title') }}</h2>
        <div class="font-s-regular">{{ t('subtitle') }}</div>
      </div>
    </accredible-tile-content>
  </a>
</accredible-tile>
