import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { FeaturedComponent } from './featured.component';

@NgModule({
  declarations: [FeaturedComponent],
  imports: [CommonModule, AccredibleIconComponentModule, TranslocoModule],
  exports: [FeaturedComponent],
})
export class FeaturedModule {}
