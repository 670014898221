import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AvatarComponent } from './avatar.component';

@NgModule({
  imports: [CommonModule, AccredibleIconComponentModule, LazyLoadImageModule],
  exports: [AvatarComponent],
  declarations: [AvatarComponent],
  providers: [],
})
export class AccredibleAvatarComponentModule {}
