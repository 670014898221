import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { shareInPopup } from '../helpers/social-share.helper';

@Component({
  selector: 'accredible-linkedin-share-button',
  templateUrl: './linkedin-share-button.component.html',
  styleUrls: ['../social-share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedInShareButtonComponent {
  @Input()
  url = this._document.location.href;

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  share(): void {
    shareInPopup(`https://www.linkedin.com/shareArticle?url=${this.url}`);
  }
}
