<!-- TODO(Fred): For now this header has specific css in order to be consistent in design with other apps -->
<ng-container *transloco="let t; read: 'cf-header'">
  <header class="cf-content-horizontal">
    <img src="/assets/images/accredible_logo_text.png"
      [alt]="t('accredible_logo')">

    <div class="user-menu"
      *ngIf="user$ | async as user; else signInTemplate">
      <a class="btn-profile"
        [href]="myCredentialsUrl">
        {{ t('my_credentials') }}
      </a>

      <button class="btn-profile"
        [accredibleMenuTriggerFor]="menu">
        <accredible-avatar [isLoading]="false"
          [imageUrl]="user.avatar"
          [name]="user.name"
          [size]="'26px'"
          [withBorder]="false">
        </accredible-avatar>
        <span class="font-normal-regular">{{ user.name }}</span>
      </button>
    </div>

    <ng-template #signInTemplate>
      <a class="btn-sign-in"
        [href]="accountsLoginUrl">
        <accredible-avatar [isLoading]="false"
          [size]="'26px'">
        </accredible-avatar>
        <span class="font-normal-regular">
          {{ t('sign_in') }}
        </span>
      </a>
    </ng-template>
  </header>

  <accredible-menu #menu>
    <div class="coursefinder-header-menu">
      <a [href]="accountsPersonalInformationUrl">{{ t('accounts_settings') }}</a>

      <a routerLink="favorite-courses">
        {{ t('favorites') }}
      </a>

      <a [href]="helpUrl | async">{{ t('help') }}</a>

      <button class="btn-sign-out"
        (click)="logout()">
        {{ t('sign_out') }}
      </button>
    </div>
  </accredible-menu>
</ng-container>
