import { AccredibleCopyToClipboardComponentModule } from '@accredible-frontend-v2/new-components/copy-to-clipboard';
import { AccredibleDialogTemplateComponentModule } from '@accredible-frontend-v2/new-components/dialog';
import { AccredibleSocialShareButtonsModule } from '@accredible-frontend-v2/new-components/social-share-buttons';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ShareDialogComponent } from './share-dialog.component';

@NgModule({
  declarations: [ShareDialogComponent],
  imports: [
    AccredibleDialogTemplateComponentModule,
    AccredibleCopyToClipboardComponentModule,
    AccredibleSocialShareButtonsModule,
    TranslocoModule,
  ],
})
export class ShareDialogComponentModule {}
