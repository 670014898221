import { AccredibleTileComponentModule } from '@accredible-frontend-v2/new-components/tile';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { FeaturedVideoComponent } from './featured-video.component';

@NgModule({
  declarations: [FeaturedVideoComponent],
  imports: [AccredibleTileComponentModule, TranslocoModule],
  exports: [FeaturedVideoComponent],
})
export class FeaturedVideoComponentModule {}
