import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleDialogTemplateComponent } from './dialog-template.component';
import { AccredibleButtonLoadingComponentModule } from '@accredible-frontend-v2/components/button-loading';

@NgModule({
  declarations: [AccredibleDialogTemplateComponent],
  imports: [
    CommonModule,
    AccredibleIconComponentModule,
    AccredibleButtonComponentModule,
    AccredibleButtonLoadingComponentModule,
  ],
  exports: [AccredibleDialogTemplateComponent],
})
export class AccredibleDialogTemplateComponentModule {}
