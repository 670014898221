import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ShareDialogComponentModule } from '../share-dialog/share-dialog.component.module';
import { ShareComponent } from './share.component';

@NgModule({
  declarations: [ShareComponent],
  imports: [
    CommonModule,
    ShareDialogComponentModule,
    AccredibleIconComponentModule,
    TranslocoModule,
  ],
  exports: [ShareComponent],
})
export class ShareComponentModule {}
