import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { SignInDialogComponentModule } from '../sign-in-dialog/sign-in-dialog.component.module';
import { FavoriteComponent } from './favorite.component';

@NgModule({
  declarations: [FavoriteComponent],
  imports: [
    CommonModule,
    SignInDialogComponentModule,
    AccredibleIconComponentModule,
    TranslocoModule,
  ],
  exports: [FavoriteComponent],
})
export class FavoriteComponentModule {}
