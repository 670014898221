import { AccredibleListComponentModule } from '@accredible-frontend-v2/new-components/list';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleMenuItemComponent } from './components/menu-item.component';
import { AccredibleMenuComponent } from './menu.component';
import { AccredibleMenuTriggerDirective } from './menu.directive';

/**
 * @deprecated This component is deprecated and should not be used anymore.
 * Use {@link https://material.angular.io/cdk/menu|CdkMenu} instead.
 * Please take a look at the <a href="libs/new-components/menu/README.md">README</a> of this library.
 */
@NgModule({
  imports: [CommonModule, OverlayModule, A11yModule, AccredibleListComponentModule],
  exports: [AccredibleMenuComponent, AccredibleMenuTriggerDirective, AccredibleMenuItemComponent],
  declarations: [
    AccredibleMenuComponent,
    AccredibleMenuTriggerDirective,
    AccredibleMenuItemComponent,
  ],
  providers: [],
})
export class AccredibleMenuComponentModule {}
