import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { Course } from '../../../core/models/course.model';

@Component({
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public readonly data: { course: Course },
    private readonly _dialogRef: DialogRef,
  ) {}

  close(): void {
    this._dialogRef.close();
  }
}
