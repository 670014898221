import { FocusableOption } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding } from '@angular/core';

@Component({
  selector: '[accredible-menu-item]',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleMenuItemComponent implements FocusableOption {
  @HostBinding('attr.role')
  role = 'menuitem';
  @HostBinding('tabindex')
  tabIndex = 0;

  constructor(private readonly _element: ElementRef) {}

  focus(): void {
    this._element.nativeElement.focus();
  }
}
