import { AccredibleDialogService } from '@accredible-frontend-v2/new-components/dialog';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Course } from '../../../core/models/course.model';
import * as CoursesActions from '../../../core/stores/courses/courses.actions';
import { CoursesState, CoursesStateAction } from '../../../core/stores/courses/courses.reducer';
import { selectCoursesStateAction } from '../../../core/stores/courses/courses.selectors';
import { SignInDialogComponent } from '../sign-in-dialog/sign-in-dialog.component';

const FAVORITE_ICON = 'heart1';
const NOT_FAVORITE_ICON = 'heart';

@UntilDestroy()
@Component({
  selector: 'cf-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteComponent {
  @Input()
  isLoading = true;
  @Input()
  course: Course;

  @Output()
  favoriteRemoved = new EventEmitter<Course>();

  @ViewChild('favoriteButton')
  favoriteButton: ElementRef;

  private _blocked = false;

  constructor(
    private readonly _userApi: AccredibleUserApiService,
    private readonly _coursesStore: Store<CoursesState>,
    private readonly _dialogService: AccredibleDialogService,
  ) {}

  get icon(): string {
    return this.course.favorited_by_current_user ? FAVORITE_ICON : NOT_FAVORITE_ICON;
  }

  toggleFavorite(event: Event): void {
    event.preventDefault();
    if (!this._userApi.getUser()) {
      this._dialogService.open(SignInDialogComponent, {
        data: {
          trigger: this.favoriteButton,
        },
      });
      return;
    }

    if (!this._blocked) {
      this._blocked = true;
      if (!this.course.favorited_by_current_user) {
        this._coursesStore.dispatch(
          CoursesActions.addFavoriteCourse({
            userId: this._userApi.getUser().id,
            courseId: this.course.id,
            courseViewId: this.course.course_view_id,
          }),
        );
      } else {
        this._coursesStore.dispatch(
          CoursesActions.removeFavoriteCourse({
            userId: this._userApi.getUser().id,
            courseId: this.course.id,
            courseViewId: this.course.course_view_id,
          }),
        );
      }

      this._coursesStore
        .select(selectCoursesStateAction)
        .pipe(
          untilDestroyed(this),
          first(
            (action) =>
              action === CoursesStateAction.FAVORITE_COURSE_ADDED ||
              action === CoursesStateAction.FAVORITE_COURSE_REMOVED ||
              action === CoursesStateAction.HAS_ERROR,
          ),
        )
        .subscribe((action) => {
          this._blocked = false;

          if (action === CoursesStateAction.FAVORITE_COURSE_REMOVED) {
            this.favoriteRemoved.emit(this.course);
          }
        });
    }
  }
}
