import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { AccredibleConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AccredibleConfirmDialogData } from '../confirm-dialog/models/confirm-dialog.model';

const DEFAULT_DIALOG_WIDTH = '600px';
const DEFAULT_CONFIRM_DIALOG_WIDTH = '400px';

@Injectable({
  providedIn: 'root',
})
export class AccredibleDialogService {
  constructor(private readonly _dialog: Dialog) {}

  /**
   * Open a custom component with the dialog cdk
   */
  open<Result, Data, Component>(
    component: ComponentType<Component>,
    config?: DialogConfig<Data, DialogRef<Result, Component>>,
  ): DialogRef<Result, Component> {
    return this._openComponent(component, config);
  }

  /**
   * Open AccredibleConfirmDialogComponent with the dialog cdk
   */
  confirm<Result>(
    data?: AccredibleConfirmDialogData,
  ): DialogRef<Result, AccredibleConfirmDialogComponent> {
    return this._openComponent(AccredibleConfirmDialogComponent, {
      width: DEFAULT_CONFIRM_DIALOG_WIDTH,
      data,
    });
  }

  closeAll(result: boolean): void {
    this._dialog.openDialogs.forEach((dialog: DialogRef) => {
      dialog.close(result);
    });
  }

  private _openComponent<Result, Data, Component>(
    component: ComponentType<Component>,
    config?: DialogConfig<Data, DialogRef<Result, Component>>,
  ): DialogRef<Result, Component> {
    return this._dialog.open(component, { width: DEFAULT_DIALOG_WIDTH, ...config });
  }
}
