import { AccredibleKey } from '@accredible-frontend-v2/utils/key-enum';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { merge, Observable, Subscription } from 'rxjs';
import { AccredibleMenuComponent } from './menu.component';

@Directive({
  selector: '[accredibleMenuTriggerFor]',
})
export class AccredibleMenuTriggerDirective implements OnDestroy {
  @Input('accredibleMenuTriggerFor')
  menu: AccredibleMenuComponent;
  @Input()
  align: 'start' | 'end' = 'start';
  @Input()
  invertPositionY = false;
  @Input()
  propagateClick = true;

  @Output()
  menuToggled = new EventEmitter<boolean>();

  @HostBinding('attr.aria-haspopup')
  hasPopUp = 'true';

  private _menuClosingActions$ = Subscription.EMPTY;
  private _isMenuOpen = false;
  private _overlayRef: OverlayRef;

  constructor(
    private readonly overlay: Overlay,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  @HostListener('click', ['$event'])
  toggleMenu(event: MouseEvent): void {
    if (!this.propagateClick) {
      event.preventDefault();
      event.stopPropagation();
    }
    this._isMenuOpen ? this._destroyMenu() : this.openMenu();
  }

  @HostListener('keydown', ['$event'])
  keyHandler(event: KeyboardEvent): void {
    switch (event.code) {
      case AccredibleKey.ENTER:
      case AccredibleKey.SPACE:
      case AccredibleKey.ARROW_UP:
      case AccredibleKey.ARROW_DOWN:
        this.openMenu();
        break;
    }
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  openMenu(): void {
    this.menu.isOpened$.next(true);
    this.menuToggled.emit(true);
    this._isMenuOpen = true;
    this._overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'accredible-menu-overlay-backdrop',
      panelClass: 'accredible-menu-overlay-panel',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: this.align,
            originY: this.invertPositionY ? 'top' : 'bottom',
            overlayX: this.align,
            overlayY: this.invertPositionY ? 'bottom' : 'top',
            offsetY: this.invertPositionY ? -5 : 5,
          },
        ]),
    });

    const templatePortal = new TemplatePortal(this.menu.templateRef, this.viewContainerRef);
    this._overlayRef.attach(templatePortal);

    this._menuClosingActions$ = this._menuClosingActions().subscribe(() => this._destroyMenu());
  }

  private _menuClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this._overlayRef.backdropClick();
    const detachment$ = this._overlayRef.detachments();
    const menuClosed$ = this.menu.closed$;

    return merge(backdropClick$, detachment$, menuClosed$);
  }

  private _destroyMenu(): void {
    if (!this._overlayRef || !this._isMenuOpen) {
      return;
    }

    this.menuToggled.emit(false);
    this.menu.isOpened$.next(false);
    this._menuClosingActions$.unsubscribe();
    this._isMenuOpen = false;
    this._overlayRef.detach();
  }
}
