import { NgModule } from '@angular/core';
import {
  AccredibleTileComponent,
  TileContentDirective,
  TileHeaderDirective,
  TileTitleDirective,
} from './tile.component';

@NgModule({
  imports: [],
  exports: [AccredibleTileComponent, TileHeaderDirective, TileContentDirective, TileTitleDirective],
  declarations: [
    AccredibleTileComponent,
    TileHeaderDirective,
    TileContentDirective,
    TileTitleDirective,
  ],
  providers: [],
})
export class AccredibleTileComponentModule {}
