import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleListComponent } from './list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AccredibleListComponent],
  exports: [AccredibleListComponent],
})
export class AccredibleListComponentModule {}
