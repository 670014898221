import { AccredibleObjectWithId } from '@accredible-frontend-v2/models';
import { SelectInputOption } from '@accredible-frontend-v2/new-components/select-input';
import { AVAILABLE_LANGUAGES } from '@accredible-frontend-v2/services/language';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CourseIssuer } from '../../models/course.model';
import { LANGUAGES_CONVERSION, mapCourseToLocalCourse } from './courses.helper';
import { coursesFeatureKey, CoursesState } from './courses.reducer';

export const selectCoursesState = createFeatureSelector<CoursesState>(coursesFeatureKey);

export const selectCoursesStateAction = createSelector(selectCoursesState, (state) => state.action);

export const selectCourses = createSelector(selectCoursesState, (state) => {
  return state.coursesPageData.results.map((course, index) =>
    mapCourseToLocalCourse(course, state.coursesParams.featured_limit, index, true),
  );
});

export const selectCoursesPageMeta = createSelector(
  selectCoursesState,
  (state) => state.coursesPageData.meta,
);

export const selectSearchCoursesParams = createSelector(
  selectCoursesState,
  (state) => state.coursesParams,
);

export const selectTrendingCourses = createSelector(selectCoursesState, (state) => {
  return state.trendingCourses.map((course, index) =>
    mapCourseToLocalCourse(course, state.trendingCoursesParams.featured_limit, index, true),
  );
});

export const selectTrendingCoursesParams = createSelector(
  selectCoursesState,
  (state) => state.trendingCoursesParams,
);

export const selectFavoriteCourses = createSelector(selectCoursesState, (state) => {
  return state.favoriteCoursesPageData.results.map((course) => mapCourseToLocalCourse(course));
});

export const selectFavoriteCoursesPageMeta = createSelector(
  selectCoursesState,
  (state) => state.favoriteCoursesPageData.meta,
);

export const selectHasActiveFilters = createSelector(
  selectCoursesState,
  (state) =>
    state.coursesParams.course_finder_categories.length > 0 ||
    state.coursesParams.providers.length > 0 ||
    state.coursesParams.skills.length > 0 ||
    state.coursesParams.languages.length > 0,
);

export const selectSelectedCategories = createSelector(selectCoursesState, (state) =>
  state.coursesParams.course_finder_categories.map(
    (category) =>
      <AccredibleObjectWithId>{
        id: category,
        name: category,
        image: `assets/images/categories/${category}.svg`,
      },
  ),
);

export const selectProviders = (isMobile = false): MemoizedSelector<CoursesState, CourseIssuer[]> =>
  createSelector(selectCoursesState, (state) => {
    return isMobile ? state.providers.slice(0, 6) : state.providers;
  });

export const selectSelectedProviders = createSelector(
  selectCoursesState,
  (state) => state.selectedProviders,
);

export const selectSkills = (
  isMobile: boolean,
): MemoizedSelector<CoursesState, AccredibleObjectWithId[]> =>
  createSelector(selectCoursesState, (state) => {
    const skills = isMobile ? state.skills.slice(0, 6) : state.skills;
    return skills.map(
      (skill) =>
        <AccredibleObjectWithId>{
          id: skill,
          name: skill,
        },
    );
  });

export const selectSelectedSkills = createSelector(selectCoursesState, (state) =>
  state.coursesParams.skills.map(
    (skill) =>
      <AccredibleObjectWithId>{
        id: skill,
        name: skill,
      },
  ),
);

const ALL_LANGUAGES = 'All Languages';

export const selectLanguages = createSelector(selectCoursesState, (state) => {
  const convertedLanguages = {};
  state.languages
    .filter((languageCode) => AVAILABLE_LANGUAGES.find((l) => l.code === languageCode)?.name)
    .reduce((languages, currentLanguageCode) => {
      const langCode =
        LANGUAGES_CONVERSION.merge[currentLanguageCode]?.value || currentLanguageCode;
      languages[langCode] =
        LANGUAGES_CONVERSION.merge[currentLanguageCode]?.name ||
        AVAILABLE_LANGUAGES.find((l) => l.code === currentLanguageCode)?.name;
      return languages;
    }, convertedLanguages);
  return [
    <SelectInputOption>{ value: '', name: ALL_LANGUAGES },
    ...Object.entries(convertedLanguages).map(
      ([value, name]) => <SelectInputOption>{ value, name },
    ),
  ];
});

export const selectSelectedLanguage = createSelector(selectCoursesState, (state) => {
  if (!state.coursesParams?.languages.length) {
    return null;
  }
  const language = state.coursesParams?.languages[0];
  const langCode = LANGUAGES_CONVERSION.merge[language]?.value || language;
  const langName =
    LANGUAGES_CONVERSION.merge[language]?.name ||
    AVAILABLE_LANGUAGES.find((l) => l.code === language)?.name;
  return <SelectInputOption>{
    value: langCode,
    name: langName,
  };
});
