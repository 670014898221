import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { AccredibleBaseSliderComponent } from './base-slider.component';

@NgModule({
  imports: [CommonModule, SwiperModule],
  declarations: [AccredibleBaseSliderComponent],
  exports: [AccredibleBaseSliderComponent],
})
export class AccredibleBaseSliderComponentModule {}
