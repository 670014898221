<button *transloco="let t; read: 'cf-share'"
  id="trkShareButton"
  class="btn-share"
  [class.ghost]="isLoading"
  (click)="openShareDialog($event)">

  <accredible-icon [icon]="'share'">
  </accredible-icon>

  <span class="font-s-semibold">{{ t('share') }}</span>

</button>
