import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleDialogService } from './service/dialog.service';

@NgModule({
  imports: [CommonModule, DialogModule],
  providers: [AccredibleDialogService],
})
export class AccredibleDialogModule {}
