import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import {
  AccredibleResponsiveBreakpoint,
  AccredibleResponsiveHelper,
} from '@accredible-frontend-v2/utils/responsive-helper';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import * as CoursesActions from './stores/courses/courses.actions';
import { CoursesState, CoursesStateAction } from './stores/courses/courses.reducer';
import {
  selectCoursesStateAction,
  selectTrendingCourses,
  selectTrendingCoursesParams,
} from './stores/courses/courses.selectors';

@UntilDestroy()
@Component({
  selector: 'cf-root',
  templateUrl: './course-finder.container.html',
  styleUrls: ['./course-finder.container.scss'],
})
export class CourseFinderContainer implements OnInit {
  readonly version = environment.version;
  readonly trendingCourses$ = this._coursesStore.select(selectTrendingCourses);
  readonly trendingCoursesParams$ = this._coursesStore.select(selectTrendingCoursesParams);

  isLoadingTrendingCourses = true;
  isDesktop = AccredibleResponsiveHelper.isScreenGreaterThan(AccredibleResponsiveBreakpoint.MD);

  constructor(
    private readonly _coursesStore: Store<CoursesState>,
    private readonly _userApi: AccredibleUserApiService,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this._loadTrendingCourses();
    this._setupStoreActionsListener();
    this._setupOnLogout();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.isDesktop = AccredibleResponsiveHelper.isScreenGreaterThan(
      AccredibleResponsiveBreakpoint.MD,
    );
  }

  private _loadTrendingCourses(): void {
    this.trendingCoursesParams$
      .pipe(untilDestroyed(this), take(1))
      .subscribe(({ limit, featured_limit }) => {
        this._coursesStore.dispatch(
          CoursesActions.loadTrendingCourses({
            trendingCoursesParams: {
              limit,
              featured_limit,
            },
          }),
        );
      });
  }

  private _setupStoreActionsListener(): void {
    this._coursesStore
      .select(selectCoursesStateAction)
      .pipe(untilDestroyed(this))
      .subscribe((action) => {
        switch (action) {
          case CoursesStateAction.TRENDING_COURSES_LOADED:
          case CoursesStateAction.TRENDING_COURSES_ERROR:
            this.isLoadingTrendingCourses = false;
            break;
        }
      });
  }

  private _setupOnLogout(): void {
    this._userApi.onLogout$.pipe(untilDestroyed(this)).subscribe((loggedOut) => {
      // On logout, refresh the page
      if (loggedOut) {
        this._router.navigate([this._router.url]);
      }
    });
  }
}
