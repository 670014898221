import { AccrediblePageData, AccrediblePageParams } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Course, CourseIssuer, CoursesParams } from '../../models/course.model';
import { CourseFinderApiService } from '../../services/cf-api/cf-api.service';

const API_ENDPOINT = '/v1/credential-net';
// TODO(Fred): Eventually all endpoints should be using the NEW_API_ENDPOINT
const NEW_API_ENDPOINT = '/v1/recipient';

@Injectable({
  providedIn: 'root',
})
export class CoursesService extends CourseFinderApiService {
  searchCourses(coursesParams: CoursesParams): Observable<AccrediblePageData<Course>> {
    return this._post(`${NEW_API_ENDPOINT}/course_finder/groups/search`, coursesParams).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  loadTrendingCourses(trendingCoursesParams: CoursesParams): Observable<Course[]> {
    const searchParams = new URLSearchParams();
    searchParams.set('limit', trendingCoursesParams.limit.toString());
    searchParams.set('featured_limit', trendingCoursesParams.featured_limit.toString());

    const url = `${NEW_API_ENDPOINT}/course_finder/groups/trending?${searchParams}`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'results')),
      catchError((res) => this._handleError(res)),
    );
  }

  loadFavoriteCourses(
    userId: number,
    pageParams: AccrediblePageParams,
  ): Observable<AccrediblePageData<Course>> {
    return this._get(
      `${NEW_API_ENDPOINT}/users/${userId}/user_favorite_groups?page=${pageParams.page}&page_size=${pageParams.page_size}`,
    ).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  addFavoriteCourse(userId: number, groupId: number, courseViewId: number): Observable<string> {
    return this._post(`${NEW_API_ENDPOINT}/users/${userId}/user_favorite_groups`, {
      user_favorite_group: { group_id: groupId },
      course_view_id: courseViewId,
    }).pipe(
      map((res) => this._handleResponse(res)),
      catchError((err) => this._handleError(err)),
    );
  }

  removeFavoriteCourse(userId: number, groupId: number, courseViewId: number): Observable<string> {
    return this._delete(
      `${NEW_API_ENDPOINT}/users/${userId}/user_favorite_groups/${groupId}?course_view_id=${courseViewId}`,
    ).pipe(
      map((res) => this._handleResponse(res)),
      catchError((err) => this._handleError(err)),
    );
  }

  loadProviders(query = ''): Observable<CourseIssuer[]> {
    const url = `${API_ENDPOINT}/organizations/search?query=${query}&page_size=20`;
    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'results')),
      catchError((res) => this._handleError(res)),
    );
  }

  loadSelectedProviders(ids: number[]): Observable<CourseIssuer[]> {
    const url = `${API_ENDPOINT}/organizations/search?ids[]=${ids.join('&ids[]=')}`;
    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'results')),
      catchError((res) => this._handleError(res)),
    );
  }

  loadSkills(query = ''): Observable<string[]> {
    const url = `${API_ENDPOINT}/learning_outcomes/search?query=${query}&page_size=20`;
    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'results')),
      catchError((res) => this._handleError(res)),
    );
  }

  loadLanguages(): Observable<string[]> {
    return this._get(`${API_ENDPOINT}/languages`).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }
}
