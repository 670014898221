import { AccredibleBaseInputInterface } from '@accredible-frontend-v2/forms/utils';
import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

let nextUniqueId = 0;
let nextUniqueDataCy = 0;

@Component({
  selector: 'accredible-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  // We are not using ChangeDetectionStrategy.OnPush because this input component
  // does not detect changes when formGroup.markAllAsTouched() is triggered
})
export class AccredibleCheckboxInputComponent implements AccredibleBaseInputInterface, OnDestroy {
  @Input()
  formControlRef: UntypedFormControl;
  @Input()
  label: string;
  @Input()
  id = `checkbox-${nextUniqueId++}`;
  @Input()
  required = false;
  @Input()
  hint: string;
  @Input()
  tabIndex: number;
  @Input()
  disabled = false;
  @Input()
  showFullText = false;
  @Input()
  dataCy = `checkbox-${nextUniqueDataCy++}`;

  @Output()
  checkboxChange = new EventEmitter<boolean>();

  @HostListener('click')
  onClick(): boolean {
    return !this.disabled;
  }

  onChange(event: Event): void {
    event.stopPropagation();
    this.checkboxChange.emit(this.formControlRef.value);
  }

  ngOnDestroy(): void {
    nextUniqueDataCy = 0;
  }
}
