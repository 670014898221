<span class="link-text"
  [class.font-s-semibold]="size === 'small'"
  [class.font-normal-semibold]="size === 'normal'">
  <ng-content></ng-content>
</span>
<accredible-icon *ngIf="icon"
  [icon]="icon"
  [class.on-left]="iconPosition === 'left'"
  [class.invert]="invertIconRtl">
</accredible-icon>
