<ng-container *transloco="let t; read: 'course-finder'">
  <div class="version">{{ version }}</div>

  <div class="coursefinder-container">
    <cf-header/>

    <router-outlet/>

    @if ((trendingCourses$ | async).length > 0) {
      <cf-trending-courses [courses]="trendingCourses$ | async"
        [isLoading]="isLoadingTrendingCourses"/>
    }

    <!-- Mobile articles -->
    @if (!isDesktop) {
      <div class="cf-articles">
        <accredible-read-more [title]="t('title')"
          [ariaLabel]="t('aria_featured_read')"
          [subtitle]="t('featured_read')"
          [urlLabel]="t('see_the_list')"
          [url]="'https://www.indeed.com/career-advice/career-development/certifications-in-demand'"/>
        <cf-featured-video/>
        <cf-blog-article/>
      </div>
    }

    <cf-footer/>
  </div>

  <accredible-cookies-banner/>
</ng-container>  