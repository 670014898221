<accredible-slider-multi *transloco="let t; read: 'trending-courses'"
  [class.ghost]="isLoading"
  [title]="t('trending_courses')"
  [usingCertificateBadgeComponents]="true">

  <accredible-slide-item *ngFor="let course of courses; trackBy: trackById">
    <accredible-tile [hasHover]="true"
      [class.featured]="course.recommendation_featured">
      <a id="trkCourseTrending"
        [class.is-tablet-or-mobile]="isTabletOrMobile"
        [attr.aria-label]="t('aria_course_link', { courseName: course.course_name, issuer: course.issuer.name })"
        [href]="course.tracked_click_link"
        target="_blank">
        <div class="appearance-container">
          <div [class.certificate-and-badge]="course.certificate_design && course.badge_design"
            [class.only-certificate]="course.certificate_design && !course.badge_design"
            class="appearance-wrapper">
            <accredible-credential-appearance [group]="course.partialCredential.group"
              [partialCredential]="course.partialCredential"
              [showLabel]="false">
            </accredible-credential-appearance>
          </div>
        </div>

        <div class="course-info">
          <div class="issuer">
            <div class="issuer-img">
              <img [src]="course.issuer.logo_url"
                alt="">
            </div>
            <div class="issuer-name">{{ course.issuer.name }}</div>
          </div>

          <div class="name-featured-container">
            <div class="name font-l-semibold">
              {{ course.course_name }}
            </div>

            <cf-featured *ngIf="course.recommendation_featured"></cf-featured>
          </div>

          <div *ngIf="course.learning_outcomes.length > 0"
            class="skills">
            <span>{{ t('skills') }}</span>
            <span *ngFor="let skill of course.learning_outcomes | slice:0:2; last as isLast">
              {{ skill }}
              <ng-container *ngIf="!isLast"> - </ng-container>
            </span>
          </div>
        </div>

        <div class="actions">
          <cf-share [isLoading]="isLoading"
            [course]="course">
          </cf-share>
          <div class="actions-divider"></div>
          <cf-favorite [isLoading]="isLoading"
            [course]="course">
          </cf-favorite>
        </div>
      </a>
    </accredible-tile>
  </accredible-slide-item>

</accredible-slider-multi>
