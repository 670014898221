import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CourseFinderLoadService } from './course-finder-load.service';

export function initApp(appLoadService: CourseFinderLoadService): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.initializeApp();
}

export function validateSessionToken(
  appLoadService: CourseFinderLoadService,
): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.validateAuthenticationToken();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    CourseFinderLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [CourseFinderLoadService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: validateSessionToken,
      deps: [CourseFinderLoadService],
      multi: true,
    },
  ],
})
export class CourseFinderLoadModule {}
