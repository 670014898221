<div *transloco="let t; read: 'favorite'"
  #favoriteButton
  id="trkFavoriteButton"
  class="btn-favorite"
  role="button"
  tabindex="0"
  [class.ghost]="isLoading"
  [attr.aria-label]="t('aria_save_as_favorite', { count: course?.user_favorite_groups_count })"
  [attr.aria-pressed]="course.favorited_by_current_user"
  (click)="toggleFavorite($event)"
  (keydown.enter)="toggleFavorite($event)">

  <accredible-icon [icon]="icon">
  </accredible-icon>

  <span *ngIf="course?.user_favorite_groups_count > 0"
    class="counter font-normal-xs">
    {{ course.user_favorite_groups_count }}
  </span>

</div>
