import { AccredibleSlideItemComponent } from '@accredible-frontend-v2/new-components/sliders/slide-item';
import { AccredibleResponsiveBreakpoint } from '@accredible-frontend-v2/utils/responsive-helper';
import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { Swiper, SwiperOptions } from 'swiper';

@Component({
  selector: 'accredible-slider-multi',
  templateUrl: './slider-multi.component.html',
  styleUrls: ['./slider-multi.component.scss'],
  // TODO(Fred): ChangeDetectionStrategy.OnPush can't be used in order for the certificates to be correctly drawn on any slider using the certificate/badge components.
  //  But, this logic will not be needed when we start using thumbnail images instead of the certificate/badge components.
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleSliderMultiComponent {
  @Input()
  title: string;
  @Input()
  usingCertificateBadgeComponents = false;

  @ContentChildren(AccredibleSlideItemComponent, { descendants: true })
  slideItems: QueryList<AccredibleSlideItemComponent>;

  // The full list of options can be found here: https://swiperjs.com/swiper-api#parameters
  sliderOptions: SwiperOptions = {
    slidesPerView: 1.3,
    slidesPerGroup: 1,
    spaceBetween: 30,
    freeMode: true,
    centeredSlides: true,
    breakpoints: {
      [AccredibleResponsiveBreakpoint.XS]: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        slidesOffsetBefore: 15,
        slidesOffsetAfter: 15,
        spaceBetween: 40,
        centeredSlides: false,
      },
      [AccredibleResponsiveBreakpoint.SM]: {
        slidesPerView: 3,
        slidesPerGroup: 2,
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
        spaceBetween: 40,
        centeredSlides: false,
      },
      [AccredibleResponsiveBreakpoint.MD]: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        slidesOffsetBefore: 35,
        slidesOffsetAfter: 35,
        spaceBetween: 40,
        centeredSlides: false,
      },
      [AccredibleResponsiveBreakpoint.ML]: {
        slidesPerView: 5,
        slidesPerGroup: 4,
        slidesOffsetBefore: 35,
        slidesOffsetAfter: 35,
        spaceBetween: 40,
        centeredSlides: false,
      },
      [AccredibleResponsiveBreakpoint.SL]: {
        slidesPerView: 6,
        slidesPerGroup: 5,
        slidesOffsetBefore: 35,
        slidesOffsetAfter: 35,
        spaceBetween: 40,
        centeredSlides: false,
      },
    },
  };
  slider: Swiper;

  get isBeginning(): boolean {
    return this.slider.isBeginning || false;
  }

  get isEnd(): boolean {
    return this.slider.isEnd || false;
  }

  next(): void {
    this.slider.slideNext();
  }

  previous(): void {
    this.slider.slidePrev();
  }

  updateSwiperReference(swiper: Swiper): void {
    this.slider = swiper;

    if (this.usingCertificateBadgeComponents) {
      // TODO(Fred): We need to dispatch a resize event in order for the certificates to be correctly drawn on any slider using the certificate/badge components.
      //  But, this logic will not be needed when we start using thumbnail images instead of the certificate/badge components.
      swiper.on('progress', () => {
        window.dispatchEvent(new Event('resize'));
      });
    }
  }
}
