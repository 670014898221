import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { shareInPopup } from '../helpers/social-share.helper';

@Component({
  selector: 'accredible-twitter-share-button',
  templateUrl: './twitter-share-button.component.html',
  styleUrls: ['../social-share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwitterShareButtonComponent {
  @Input()
  url = this._document.location.href;
  @Input()
  text = '';

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  share(): void {
    shareInPopup(`https://twitter.com/share?url=${this.url}&text=${this.text}`);
  }
}
