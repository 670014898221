<accredible-tile *transloco="let t; read: 'blog-article'">
  <a id="trkBlogArticleLink"
    target="_blank"
    href="https://www.accredible.com/blog/mobile-friendly-digital-credentials-always-available-credentials-for-on-the-go-verification"
    [attr.aria-label]="t('aria_blog_article')">
    <accredible-tile-content>
      <div class="left">
        <div class="blog-article-text font-s-semibold">{{ t('blog_article') }}</div>
        <h2 class="font-l-semibold">{{ t('title') }}</h2>
        <div class="font-s-regular">{{ t('subtitle') }}</div>

        <a id="trkBlogArticleLinkIn"
          accredible-link
          target="_blank"
          href="https://www.accredible.com/blog/mobile-friendly-digital-credentials-always-available-credentials-for-on-the-go-verification">
          {{ t('learn_more') }}
        </a>
      </div>

      <div class="right">
        <img src="assets/images/blog_article_img.svg"
          alt="">
      </div>
    </accredible-tile-content>
  </a>
</accredible-tile>
