<div class="dialog-panel"
  role="dialog"
  aria-modal="true"
  tabindex="0"
  (keydown.escape)="close()">

  <div class="dialog-header"
    [class.has-title]="title">
    <h1 *ngIf="title"
      class="font-normal-semibold">
      {{ title }}
    </h1>

    <accredible-icon *ngIf="closeBtn"
      [icon]="'close'"
      role="button"
      tabindex="0"
      (keydown.enter)="close()"
      (click)="close()">
    </accredible-icon>
  </div>

  <div *ngIf="subtitle"
    class="dialog-subheader">
    <h2 class="font-multiline-s-regular">{{ subtitle }}</h2>
  </div>

  <div *ngIf="title"
    class="dialog-header-divider">
  </div>

  <div class="dialog-content">
    <ng-content></ng-content>
  </div>

  <div *ngIf="actionBtnClicked.observed"
    class="dialog-actions"
    [class.centered]="centered">
    <button *ngIf="cancelBtnText"
      accredible-button-flat
      data-cy="btn-dialog-cancel"
      type="button"
      [color]="'natural'"
      (click)="close(false)">
      {{ cancelBtnText }}
    </button>

    <button accredible-button
      data-cy="btn-dialog-action"
      [disabled]="actionBtnDisabled || isLoading"
      [isLoading]="isLoading"
      [color]="actionBtnColor"
      (click)="submit()">
      {{ actionBtnText }}
    </button>
  </div>
</div>
