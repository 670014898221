import { AccredibleLinkComponentModule } from '@accredible-frontend-v2/new-components/link';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { FooterComponent } from './footer.component';

@NgModule({
  imports: [AccredibleLinkComponentModule, TranslocoModule],
  exports: [FooterComponent],
  declarations: [FooterComponent],
  providers: [],
})
export class FooterComponentModule {}
