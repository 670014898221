<accredible-linkedin-share-button [url]="url">
</accredible-linkedin-share-button>

<accredible-twitter-share-button [url]="url"
  [text]="text">
</accredible-twitter-share-button>

<accredible-facebook-share-button [url]="url">
</accredible-facebook-share-button>

<accredible-whatsapp-share-button [url]="url"
  [text]="text">
</accredible-whatsapp-share-button>

<accredible-email-share-button [url]="url"
  [text]="emailText">
</accredible-email-share-button>
