import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAPIMockData, AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CourseFinderApiService extends AccredibleApiService {
  protected _handleError(
    res: HttpErrorResponse,
    _propagate404 = false,
    mockData?: AccredibleAPIMockData,
  ): Observable<any> {
    switch (res.status) {
      case 401:
        this.cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
        break;

      case 400:
      case 404:
      default:
        if (mockData) {
          return of(this._handleResponse(mockData.res, mockData.dataKey1, mockData.dataKey2));
        }

        return throwError(res.error);
    }
  }
}
