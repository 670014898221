import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { shareInPopup } from '../helpers/social-share.helper';

@Component({
  selector: 'accredible-whatsapp-share-button',
  templateUrl: './whatsapp-share-button.component.html',
  styleUrls: ['../social-share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsAppShareButtonComponent {
  @Input()
  url = this._document.location.href;
  @Input()
  text = '';

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  share(): void {
    shareInPopup(`https://api.whatsapp.com/send?text=${this.text} ${this.url}`);
  }
}
