import { AccredibleLinkComponentModule } from '@accredible-frontend-v2/new-components/link';
import { AccredibleTileComponentModule } from '@accredible-frontend-v2/new-components/tile';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { BlogArticleComponent } from './blog-article.component';

@NgModule({
  imports: [AccredibleTileComponentModule, AccredibleLinkComponentModule, TranslocoModule],
  exports: [BlogArticleComponent],
  declarations: [BlogArticleComponent],
  providers: [],
})
export class BlogArticleComponentModule {}
