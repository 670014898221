import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageService,
  registerSupportedLocalesData,
} from '@accredible-frontend-v2/services/language';
import { addGoogleTagManager } from '@accredible-frontend-v2/utils/google-tag-manager-helper';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { Inject, Injectable } from '@angular/core';

const TOKEN_PARAM = 'token';

@Injectable()
export class CourseFinderLoadService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _authenticationApi: AccredibleAuthenticationApiService,
    private readonly _cookies: AccredibleCookiesService,
    private readonly _language: AccredibleLanguageService,
  ) {}

  initializeApp(): Promise<boolean> {
    return new Promise((resolve) => {
      // We can do pre-app initialization here
      this._setLanguages();
      addGoogleTagManager();
      resolve(true);
    });
  }

  validateAuthenticationToken(): Promise<boolean> {
    return new Promise((resolve) => {
      const urlParams = new URLSearchParams(this._document.location.search);

      // Check if session_token is in URL query params
      if (urlParams.has(TOKEN_PARAM)) {
        this._cookies.set(AccountsRedirectionKey.SESSION_TOKEN_COOKIE, urlParams.get(TOKEN_PARAM));
        this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));

        // Remove session_token from URL query params
        WindowHelper.removeQueryParamFromUrl(TOKEN_PARAM, urlParams);
      } else if (this._cookies.check(AccountsRedirectionKey.SESSION_TOKEN_COOKIE)) {
        // If session_token exists, user is logged in
        this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));
      } else {
        // User is not logged in
        resolve(false);
      }
    });
  }

  private _checkSession(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Test if token has not expired
      this._authenticationApi.checkSession().subscribe({
        // Success: User is logged in
        next: () => resolve(true),
        // Error (401): The session token has expired or the user logged out in other app
        // Our ApiService will delete our session_token from cookies and redirect the user to the login page
        error: () => resolve(false),
      });
    });
  }

  private _setLanguages(): void {
    // Register supported languages
    // const supportedLanguages = getAvailableLanguages();

    // TODO: Only en is supported for now
    registerSupportedLocalesData([
      {
        code: 'en',
        name: 'English (US)',
        matLib: localeEn,
      },
    ]);

    // Set active language
    this._language.setLanguage('en');
  }
}
